import React, { useContext, useEffect, useState } from 'react';
import styles from './styles.module.css';
import NavBar from '../../components/navbar';
import Footer from '../../components/footer';
import {
  rightDownArrow,
  rightUpArrow,
  step1Img,
  step2Img,
  step3Img,
  step4Img,
  step5Img,
} from '../../resources/images';
import Divider from '../../components/divider';
import TestimonialsSection from '../../components/testimonialsection';
import ServiceSection from '../../components/servicesection';
import { useLocation } from 'react-router';
import { Helmet } from 'react-helmet';
import { AppDataContext } from '../../providers/appDataProvider';

const Services = () => {
  const { strings } = useContext(AppDataContext);
  const location = useLocation();
  useEffect(() => {
    if (location.state === 'App development') {
      document.getElementById('01').scrollIntoView();
    } else if (location.state === 'Web development') {
      document.getElementById('02').scrollIntoView();
    } else if (location.state === 'UX/UI design') {
      document.getElementById('03').scrollIntoView();
    } else {
      window.scrollTo({ top: 0, behavior: 'auto' });
    }
  }, [location.state]);

  const [appDevStepCount, setAppDevStepCount] = useState(1);
  const [webDevStepCount, setWebDevStepCount] = useState(1);
  const [uiUxDevStepCount, setUiUxDevStepCount] = useState(1);

  const chooseUsData = [
    {
      head: strings.servicesStrings.chooseUsHeaderOne,
      desc: strings.servicesStrings.chooseUsDescpOne,
    },
    {
      head: strings.servicesStrings.chooseUsHeaderTwo,
      desc: strings.servicesStrings.chooseUsDescpTwo,
    },
    {
      head: strings.servicesStrings.chooseUsHeaderThree,
      desc: strings.servicesStrings.chooseUsDescpThree,
    },
    {
      head: strings.servicesStrings.chooseUsHeaderFour,
      desc: strings.servicesStrings.chooseUsDescpFour,
    },
  ];
  const servicesData = [
    {
      id: '01',
      uuid: 'appCards',
      head: strings.servicesStrings.appDevelopmentHeader,
      stepCount: appDevStepCount,
      setStepCount: setAppDevStepCount,
      desc: strings.servicesStrings.appDevelopmentDescp,
      stepsData: [
        {
          id: '01',
          name: strings.servicesStrings.appStepOne,
          image: step1Img,
          bgColor: '#DADEE3',
          color: '#303E37',
        },
        {
          id: '02',
          name: strings.servicesStrings.appStepTwo,
          image: step2Img,
          bgColor: '#28292C',
          color: '#DADEE3',
        },
        {
          id: '03',
          name: strings.servicesStrings.appStepThree,
          image: step3Img,
          bgColor: '#303E37',
          color: '#DADEE3',
        },
        {
          id: '04',
          name: strings.servicesStrings.appStepFour,
          image: step4Img,
          bgColor: '#30313E',
          color: '#DADEE3',
        },
        {
          id: '05',
          name: '',
          image: '',
          bgColor: '#191A19',
          color: '#191A19',
          border: '#ffffff33',
        },
      ],
    },
    {
      id: '02',
      uuid: 'webCards',
      head: strings.servicesStrings.webDevelopmentHeader,
      stepCount: webDevStepCount,
      setStepCount: setWebDevStepCount,
      desc: strings.servicesStrings.webDevelopmentDescp,
      stepsData: [
        {
          id: '01',
          name: strings.servicesStrings.appStepOne,
          image: step1Img,
          bgColor: '#DADEE3',
          color: '#303E37',
        },
        {
          id: '02',
          name: strings.servicesStrings.appStepTwo,
          image: step2Img,
          bgColor: '#28292C',
          color: '#DADEE3',
        },
        {
          id: '03',
          name: strings.servicesStrings.appStepThree,
          image: step3Img,
          bgColor: '#303E37',
          color: '#DADEE3',
        },
        {
          id: '04',
          name: strings.servicesStrings.appStepFour,
          image: step4Img,
          bgColor: '#30313E',
          color: '#DADEE3',
        },
        {
          id: '05',
          name: '',
          image: '',
          bgColor: '#191A19',
          color: '#191A19',
          border: '#ffffff33',
        },
      ],
    },
    {
      id: '03',
      head: strings.servicesStrings.uiDevelopmentHeader,
      uuid: 'uiuxCards',
      stepCount: uiUxDevStepCount,
      setStepCount: setUiUxDevStepCount,
      desc: strings.servicesStrings.uiDevelopmentDescp,
      stepsData: [
        {
          id: '01',
          name: strings.servicesStrings.uiStepOne,
          image: step1Img,
          bgColor: '#DADEE3',
          color: '#303E37',
        },
        {
          id: '02',
          name: strings.servicesStrings.uiStepTwo,
          image: step2Img,
          bgColor: '#28292C',
          color: '#DADEE3',
        },
        {
          id: '03',
          name: strings.servicesStrings.uiStepThree,
          image: step3Img,
          bgColor: '#303E37',
          color: '#DADEE3',
        },
        {
          id: '04',
          name: strings.servicesStrings.uiStepFour,
          image: step4Img,
          bgColor: '#30313E',
          color: '#DADEE3',
        },
        {
          id: '05',
          name: strings.servicesStrings.uiStepFive,
          image: step5Img,
          bgColor: '#DADEE3',
          color: '#303E37',
        },
        {
          id: '06',
          name: '',
          image: '',
          bgColor: '#191A19',
          color: '#191A19',
          border: '#ffffff33',
        },
      ],
    },
  ];

  // const appDevStepsData = [
  //   {
  //     id: '01',
  //     name: 'Backend',
  //     image: step1Img,
  //     bgColor: '#DADEE3',
  //     color: '#303E37',
  //   },
  //   {
  //     id: '02',
  //     name: 'Frontend',
  //     image: step2Img,
  //     bgColor: '#28292C',
  //     color: '#DADEE3',
  //   },
  //   {
  //     id: '03',
  //     name: 'Testing',
  //     image: step3Img,
  //     bgColor: '#303E37',
  //     color: '#DADEE3',
  //   },
  //   {
  //     id: '04',
  //     name: 'Deploying',
  //     image: step4Img,
  //     bgColor: '#30313E',
  //     color: '#DADEE3',
  //   },
  // {
  //   id: '05',
  //   name: 'High fidelity',
  //   image: step5Img,
  //   bgColor: '#DADEE3',
  //   color: '#303E37',
  // },
  // {
  //   id: '06',
  //   name: 'Get feedback',
  //   image: step6Img,
  //   bgColor: '#28292C',
  //   color: '#DADEE3',
  // },
  // {
  //   id: '07',
  //   name: 'Iterate',
  //   image: step7Img,
  //   bgColor: '#303E37',
  //   color: '#DADEE3',
  // },
  // {
  //   id: '08',
  //   name: 'Handoff',
  //   image: step8Img,
  //   bgColor: '#30313E',
  //   color: '#DADEE3',
  // },
  // ];
  const renderBannerSection = () => {
    return (
      <div className={styles.bannerMainContainerStyles}>
        <h2 className={styles.bannerHeadingTextStyles}>
          {strings.servicesStrings.headerOne}
        </h2>
        {/* <div className={styles.rightDownArrowImageStyles}>
          <img
            src={rightDownArrow}
            alt="arrow icon"
            className={styles.imageWidthStyles}
          />
        </div> */}
        <p className={styles.bannerDescTextStyles}>
          {strings.servicesStrings.descpOne}
        </p>
      </div>
    );
  };
  const renderOurServicesSection = () => {
    return (
      <div className={styles.ourServicesMainContainerStyes}>
        <h2 className={styles.ourServicesHeadingTextStyles}>Our services:</h2>
        <div className={styles.servicesContentContainerStyles}>
          {servicesData.map((item, index) => (
            <div key={index} className={styles.serviceContainerStyles}>
              <h3 className={styles.serviceTypeTextStyles}>
                {item.id}.{item.head}
              </h3>
              <div className={styles.serviceRightContainerStyles}>
                <p className={styles.serviceDescTextStyles}>{item.desc}</p>
                <p className={styles.stepsInvolvedTextStyles}>
                  STEPS INVOLVED
                  <img
                    src={rightUpArrow}
                    alt="arrow icon"
                    className={styles.rightUpArrowImageStyles}
                  />
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };
  const renderVisionSection = () => {
    return (
      <div className={styles.visionOuterContainStyles}>
        <div className={styles.visionMainContainStyles}>
          <h2 className={styles.visionHeadingTextStyles}>
            {strings.servicesStrings.headerTwo}
          </h2>
          <div className={styles.rightDownArrowImageStyles}>
            <img
              src={rightDownArrow}
              alt="arrow icon"
              className={styles.imageWidthStyles}
            />
          </div>
        </div>
      </div>
    );
  };
  const renderChooseUsSection = () => {
    return (
      <div className={styles.chooseUsContainerStyles}>
        <h2 className={styles.chooseUsHeadingTextStyles}>
          {strings.servicesStrings.headerTwo}
        </h2>
        <div className={styles.chooseUsContentContainer}>
          {chooseUsData.map((item, index) => (
            <div key={index} className={styles.chooseUsBlockStyles}>
              <h4 className={styles.chooseUsBlockHeadingTextStyles}>
                {item.head}
              </h4>
              <p className={styles.chooseUsBlockDescTextStyles}>{item.desc}</p>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.servicesMainContainerStyles}>
      <Helmet>
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js-eu1.hs-scripts.com/27027103.js"
        ></script>
      </Helmet>
      <NavBar theme={'dark'} />
      {renderBannerSection()}
      <Divider />
      {servicesData.map((service, index) => (
        <div id={service.id} key={index}>
          <ServiceSection
            stepsData={service.stepsData}
            stepCount={service.stepCount}
            setStepCount={service.setStepCount}
            serviceId={service.id}
            serviceName={service.head}
            serviceDesc={service.desc}
            serviceCardId={service.uuid}
          />{' '}
        </div>
        // {/* <Divider /> */}
      ))}
      <div className={styles.chooseUsMainContainerStyles}>
        {renderChooseUsSection()}
      </div>
      <div className={styles.testimonialContainerStyles}>
        <TestimonialsSection />
      </div>
      <Divider />
      <Footer />
    </div>
  );
};

export default Services;
