import React, { useContext } from 'react';
import styles from './styles.module.css';
import { CrossMarkIcon, RightMarkIcon } from '../../resources/svg';
import { AppDataContext } from '../../providers/appDataProvider';

const CookiePolicyPopUp = (props) => {
  const { setShowCookiePopUp, theme, showMenu } = props;
  const { strings } = useContext(AppDataContext);
  return (
    <div
      className={
        !theme
          ? showMenu
            ? styles.cookiePolicyMainContainerStyles
            : styles.cookiePolicyMainDarkContainerStyles
          : showMenu
          ? styles.cookiePolicyMainDarkContainerStyles
          : styles.cookiePolicyMainContainerStyles
      }
      style={{ display: showMenu ? 'none' : 'flex' }}
    >
      <div className={styles.cookiePolicyInnerContainerStyles}>
        <p className={styles.cookiePolicyTextStyles}>
          {strings.cookiesPopUp.headerOne}
          <span
            onClick={() => window.open('/privacypolicy', '_blank')}
            className={
              !theme
                ? showMenu
                  ? styles.cookiePolicyLinkTextStyles
                  : styles.cookiePolicyLinkDarkTextStyles
                : showMenu
                ? styles.cookiePolicyLinkDarkTextStyles
                : styles.cookiePolicyLinkTextStyles
            }
          >
            &nbsp;{strings.cookiesPopUp.cookiePolicy}
          </span>
        </p>
        <div className={styles.cookiePolicyRightContainerStyles}>
          <p
            className={styles.agreeTextStyles}
            onClick={() => {
              setShowCookiePopUp(false);
              localStorage.setItem('cookiePolicy', 'agree');
            }}
          >
            <RightMarkIcon
              customStyles={styles.rightMarkIconStyles}
              pathStyles={
                !theme
                  ? showMenu
                    ? styles.rightMarkIconPathStyles
                    : styles.rightMarkIconDarkPathStyles
                  : showMenu
                  ? styles.rightMarkIconDarkPathStyles
                  : styles.rightMarkIconPathStyles
              }
            />
            {strings.cookiesPopUp.agree}
          </p>
          <p
            className={styles.declineTextStyles}
            onClick={() => {
              setShowCookiePopUp(false);
              localStorage.setItem('cookiePolicy', 'decline');
            }}
          >
            <CrossMarkIcon
              customStyles={styles.crossMarkIconStyles}
              pathStyles={
                !theme
                  ? showMenu
                    ? styles.crossMarkIconPathStyles
                    : styles.crossMarkIconDarkPathStyles
                  : showMenu
                  ? styles.crossMarkIconDarkPathStyles
                  : styles.crossMarkIconPathStyles
              }
            />
            {strings.cookiesPopUp.decline}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CookiePolicyPopUp;
