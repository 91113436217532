import React, { useContext, useEffect } from 'react';
import ProjectCard from '../../components/projectcard';
import NavBar from '../../components/navbar';
import Footer from '../../components/footer';
import {
  bytBooOg,
  bytBooZb,
  featuresSampleImg,
  imviOg,
  imviZb,
  moreSpaceOg,
  moreSpaceZb,
  mrStudioOg,
  mrStudioZb,
  rdlaOg,
  rdlaZb,
  trakrOg,
  trakrZb,
} from '../../resources/images';
import Divider from '../../components/divider';
import { useNavigate } from 'react-router';
import { RightUpArrow } from '../../resources/svg';
import styles from './styles.module.css';
import { Helmet } from 'react-helmet';
import { AppDataContext } from '../../providers/appDataProvider';

const Projects = () => {
  const { strings } = useContext(AppDataContext);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
    const projectEles = [...document.querySelectorAll('#project')];
    projectEles.forEach((item) => {
      item.style.gap = `calc(100% - ${
        item.childNodes[0].clientWidth + item.childNodes[1].clientWidth
      }px)`;
    });
  }, []);
  const projectCardsData = [
    {
      image: mrStudioOg,
      hoverImage: mrStudioZb,
      name: strings.projectStrings.projectNameOne,
      type: strings.projectStrings.projectTypeOne,
    },
    {
      image: bytBooOg,
      hoverImage: bytBooZb,
      name: strings.projectStrings.projectNameTwo,
      type: strings.projectStrings.projectTypeOne,
    },
    {
      image: trakrOg,
      hoverImage: trakrZb,
      name: strings.projectStrings.projectNameThree,
      type: strings.projectStrings.projectTypeThree,
    },
    {
      image: imviOg,
      hoverImage: imviZb,
      name: strings.projectStrings.projectNameFour,
      type: strings.projectStrings.projectTypeFour,
    },
    {
      image: moreSpaceOg,
      hoverImage: moreSpaceZb,
      name: strings.projectStrings.projectNameFive,
      type: strings.projectStrings.projectTypeFive,
    },
  ];
  const projects = [
    {
      image: rdlaOg,
      hoverImage: rdlaZb,
      name: strings.projectStrings.projectNameSix,
      routeName: 'RosholmDell',
      type: strings.projectStrings.projectTypeSix,
    },
    {
      image: featuresSampleImg,
      name: strings.projectStrings.projectNameSeven,
      routeName: 'Foundi',
      type: strings.projectStrings.projectTypeSix,
    },
    {
      image: featuresSampleImg,
      name: strings.projectStrings.projectNameEight,
      routeName: 'Prizbee',
      type: strings.projectStrings.projectTypeEight,
    },
    {
      image: featuresSampleImg,
      name: strings.projectStrings.projectNameNine,
      routeName: 'Brygghallen',
      type: strings.projectStrings.projectTypeEight,
    },
    {
      image: featuresSampleImg,
      name: strings.projectStrings.projectNameTen,
      routeName: 'Gokaya',
      type: strings.projectStrings.projectTypeEight,
    },
    {
      image: featuresSampleImg,
      name: strings.projectStrings.projectNameEleven,
      routeName: 'Timesheets',
      type: strings.projectStrings.projectTypeEight,
    },
    // {
    //   image: featuresSampleImg,
    //   name: strings.projectStrings.projectNameTweleve,
    //   routeName: 'Comfoo',
    //   type: strings.projectStrings.projectTypeEight,
    // },
    {
      image: featuresSampleImg,
      name: strings.projectStrings.projectNameTweleve,
      routeName: 'SOOP',
      type: strings.projectStrings.projectTypeSix,
    },
    {
      image: featuresSampleImg,
      name: strings.projectStrings.projectNameThirteen,
      routeName: 'WellnessStudio',
      type: strings.projectStrings.projectTypeSix,
    },
    {
      image: featuresSampleImg,
      name: strings.projectStrings.projectNameFourteen,
      routeName: 'QGames',
      type: strings.projectStrings.projectTypeSix,
    },
  ];
  // const [activeTab, setActiveTab] = useState('All');
  const navigate = useNavigate();
  // const [projectsData, setProjectsData] = useState(projects);
  // const tabsData = ['All', 'Design', 'Development', 'Research', 'Others'];
  // const handleTabClick = (item) => {
  //   setActiveTab(item);
  // };
  const renderProjectsBottomSections = () => {
    return (
      <div className={styles.projectsBottomSectionStyles}>
        {projects.map((item, index) => (
          <div
            className={styles.projectContainerStyles}
            id="project"
            key={index}
            onClick={() => navigate(`/projects/${item.routeName}`)}
          >
            <div className={styles.projectNameContainerStyles} id="projectName">
              <RightUpArrow
                customStyles={styles.rightUpArrowStyles}
                pathStyles={styles.rightUpArrowPathStyles}
              />
              <p className={styles.projectNameTextStyles}>{item.name}</p>
            </div>
            <p className={styles.projectTypeTextStyles} id="projectType">
              -&nbsp;{item.type}
            </p>
          </div>
        ))}
      </div>
    );
  };
  return (
    <div className={styles.projectsMainContainerStyles}>
      <Helmet>
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js-eu1.hs-scripts.com/27027103.js"
        ></script>
      </Helmet>
      <NavBar theme={'dark'} />
      <div className={styles.projectsContainerStyles}>
        <h2 className={styles.projectPageHeadingTextStyles}>
          {strings.projectStrings.HeaderOne}
        </h2>
        {/* <div className={styles.tabNamesContainerStyles}>
          {tabsData.map((item, index) => (
            <p
              className={
                item === activeTab
                  ? styles.activeTabNameTextStyles
                  : styles.tabNameTextStyles
              }
              key={index}
              onClick={() => handleTabClick(item)}
            >
              {item}
            </p>
          ))}
        </div> */}
        <div className={styles.projectsCardsOuterContainerStyles}>
          <div className={styles.individualProjectsContainerStyles}>
            <ProjectCard
              customCardStyles={styles.projectBigCardStyles}
              customImageStyles={styles.projectBigCardImageStyles}
              project={projectCardsData[0]}
            />
          </div>
          <div className={styles.individualProjectsContainerStyles2}>
            <ProjectCard
              customCardStyles={styles.projectSmallCardStyles1}
              customImageStyles={styles.projectSmallCardImageStyles1}
              project={projectCardsData[1]}
            />
          </div>
          <div className={styles.individualProjectsContainerStyles3}>
            <ProjectCard
              customCardStyles={styles.projectSmallCardStyles}
              customImageStyles={styles.projectSmallCardImageStyles}
              project={projectCardsData[2]}
            />
            <ProjectCard
              customCardStyles={styles.projectSmallCardStyles1}
              customImageStyles={styles.projectSmallCardImageStyles1}
              project={projectCardsData[3]}
            />
          </div>
          <div className={styles.individualProjectsContainerStyles}>
            <ProjectCard
              customCardStyles={styles.projectBigCardStyles}
              customImageStyles={styles.projectBigCardImageStyles}
              project={projectCardsData[4]}
            />
          </div>
        </div>
      </div>

      {renderProjectsBottomSections()}
      <Divider />
      <Footer />
    </div>
  );
};

export default Projects;
