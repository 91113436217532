import {
  bannerImg6,
  bp1Og,
  bp1Zb,
  bp2Og,
  bp2Zb,
  bp3Og,
  bp3Zb,
  bp4Og,
  bp4Zb,
  bp5Og,
  bp5Zb,
  bryggHifi1,
  bryggHifi2,
  bryggOg,
  bytBoo2,
  bytBooHifi1,
  bytBooHifi2,
  bytBooOg,
  foundi2,
  foundiHifi1,
  foundiHifi2,
  foundiHifi3,
  foundiHifi4,
  foundiHifi5,
  foundiOg,
  gokayaHifi1,
  gokayaOg,
  imvi2,
  imvi3,
  imviHifi1,
  imviHifi2,
  imviHifi3,
  imviOg,
  imviWireframes,
  moreSpace2,
  moreSpaceHifi1,
  moreSpaceHifi2,
  moreSpaceHifi3,
  moreSpaceHifi4,
  moreSpaceOg,
  mrStudio2,
  mrStudioHifi1,
  mrStudioHifi2,
  mrStudioOg,
  prizbee2Og,
  prizbeeHifi1,
  qGamesHifi1,
  qGamesHifi2,
  qGamesHifi3,
  qGamesOg,
  rdlaHifi1,
  rdlaHifi2,
  rdlaHifi3,
  rdlaHifi4,
  rdlaHifi5,
  rdlaHifi6,
  rdlaOg,
  soopHifi1,
  soopHifi2,
  soopOg,
  // news1Og,
  testimonialSampleProfile,
  timeSheetsHifi1,
  timeSheetsHifi2,
  timeSheetsOg,
  trakr2,
  trakrHifi1,
  trakrHifi2,
  trakrHifi3,
  trakrHifi4,
  trakrHifi5,
  trakrHifi6,
  trakrHifi7,
  trakrOg,
  wellnessHifi1,
  wellnessHifi2,
  wellnessOg,
} from '../../resources/images';

const navbarStrings = {
  about: 'About',
  projects: 'Projects',
  services: 'Services',
  contact: 'Contact',
  insights: 'Insights',
  close: 'CLOSE',
  menu: 'MENU',
};

const common = {
  aboutUs: 'ABOUT US',
};

const footerStrings = {
  header: 'Get in touch',
  descp: `Stay up to date on projects we’ve recently launched, what we’re working on, and other news.`,
  email: 'Email address',
  subscribe: 'Subscribe',
  location: '    HETCH AB, HENCKELS TORG 3,',
  country: ' 252 25 HELSINGBORG, SWEDEN',
  mail: 'bharath@viljetech.com',
  mobileNum: '+46(0)724430170',
  insta: '  INSTAGRAM',
  linkedin: 'LINKEDIN',
  terms: ' TERMS & SERVICES',
  cookies: 'COOKIE POLICY',
  rights: '  © 2023 - ALL RIGHTS RESERVED',
  regdNo: ' ORGANISATION No - 559339-9222',
};

const homePage = {
  headerOne: 'Crafting top-notch digital solutions.',
  descpOne: ` We are a design & development agency based in Malmö, Skåne who
  reinvents the existing, scales the new, and develops digital
  solutions that will shape the future. Being both engineers &
  creatives, we understand the challenges of crafting digital products
  that satisfy both business and end-user needs.`,
  headerTwo: 'In short, we de',
  headerMobileTwo: 'We de',
  design: 'sign',
  deliver: 'liver',
  develop: 'velop',
  headerThree: 'Featured',
  all: ' ALL',
  work: 'WORK',
  allWork: ' ALL WORK',
  projectNameOne: 'Trakr',
  projectTypeOne: 'UI/UX design, Development',
  projectNameTwo: 'Bytboo',
  projectTypeTwo: 'Web development',
  projectNameThree: 'Morespace',
  projectTypeThree: 'Research & Wireframes',
  projectNameFour: 'IMVI',
  projectTypeFour: 'UI/UX design, App development',
  projectNameFive: 'MRstudio',
  projectTypeFive: 'UI/UX design, Development',
  headerFour: `We provide cutting-edge digital solutions tailored to meet the unique needs of your business and help you thrive in today's competitive landscape.`,
  servicesHeadingOne: 'App development',
  servicesDescpOne:
    'Our app development services are the perfect fit for bold and creative companies looking to make a statement in the digital space.',
  servicesHeadingTwo: 'Web development',
  servicesDescpTwo:
    'From e-commerce to interactive web apps, our web services are tailored to meet your specific needs and help your business thrive online.',
  servicesHeadingThree: 'UX/UI design',
  servicesDescpThree:
    'We prioritize usability, accessibility, and visual appeal to create intuitive and engaging interfaces that delight users and drive engagement.',
};

const testimonialStrings = {
  testimonialHeader: ' We are trusted by many ',
  clients: 'clients',
  and: 'and',
  partner: 'partners',
  around: 'around Sweden:',
  headerOne: 'Helsingborg',
  headerTwo: 'Minc',
  headerThree: 'Vinnova',
  headerFour: 'RosholmDell',
  headerFive: 'Hetch',
  know: 'Know',
  testimonialHeaderTwo: 'what our clients have to say',
  aboutSpan: 'about us:',
  descpOne: `Vilje Tech built our complex eye coordination training app and streamlined development efficiently and qualitatively from MVP to a complete mobile application. Our unique solution had several specially built functions which Vilje Tech developed. They had clear and transparent communication and were highly involved throughout the project; we will continue working together. We highly recommend Vilje Tech.`,
  clientNameOne: 'Michael Malmqvist (CIO, IMVI Labs, Sweden)',
  descpTwo: `Vilje Tech was in charge of building Bytboo's frontend when we rebuild the product from scratch. I am very happy with their work. Continuous updates along the way, pragmatic thinking presenting solutions to any obstacles we faced. The end result was spotless. I can definitely recommend Vilje Tech to any company in the same situation as Bytboo.`,
  clientNameTwo: 'Herman Treschow (CEO, Bytboo AB)',
  descpThree: `Vilje Tech has truly excelled in the reconstruction of our pre-existing website using our Figma design files. The scope of their work encompassed the frontend of our website, which was built in WordPress and Elementor. While the process and project management had occasional challenges as it was a slightly unconventional process for both parties, the end result has undeniably surpassed our expectations. The improved speed and responsiveness of our website are impressive, which has resulted in a high-performance website.`,
  clientNameThree: 'Marketing Manager, MRstudio',
};

const recentPostStrings = {
  header: 'Recent posts',
  posts: 'ALL POSTS',
};

const aboutStrings = {
  header: 'Since 2021',
  descp:
    'Our vision is to change the world by developing the ideas of tomorrow. Creations that will alter the status quo and enable us to think and act for a better future.',
  headerTwo: 'A brand can only be as robust as their core values:',
  teamsHeaderOne: 'We are progressive',
  teamsDescpOne: `We bring a unique perspective to every project, constantly pushing the boundaries of what's possible. Our focus on progress and continuous improvement allows us to provide exceptional results for our clients.`,
  teamsHeaderTwo: 'We believe in synergy',
  teamsDescpTwo: `We understand the power of collaboration. Our commitment to synergy drives us to bring diverse perspectives and expertise together, creating a dynamic and magical experience for our clients.`,
  teamsHeaderThree: 'We celebrate diversity',
  teamsDescpThree: `We approach work with a celebration of diversity in ideas, people, knowledge, and cultures. Our inclusive mindset drives us to seek out new perspectives and create innovative solutions for our clients. By embracing diversity, we are able to provide a wider range of expertise and deliver exceptional results.`,
  teamsHeaderFour: 'We act with integrity',
  teamsDescpFour: `At our company, integrity drives every action we take and shapes the quality of everything we create. Our commitment to truthfulness and respect ensures that we consistently deliver exceptional results for our clients.`,
  headerThree:
    'Onboarding to handoff, here is how we approach every single project:',
  onboarding: '01. Onboarding',
  research: '02. Research',
  testing: '03. Look testing',
  lowFidelity: '04. Low fidelity',
  highFidelity: '05. High fidelity',
  feedback: '06. Get feedback',
  iterate: '07. Iterate',
  handoff: '08. Handoff',
  headerFour: ` We’re a team of problem solvers, designers, developers, and more importantly passionate individuals.`,
  developingHeader: 'Developing team',
  personOne: 'Person name',
  personRoleOne: 'Developer',
  designHeader: 'Design team',
  designerOne: 'Person name',
  designerTypeOne: 'Designer',
  marketingHeader: 'Management, Marketing, Sales',
  marketingOne: 'Person name',
  marketingTypeOne: 'CEO',
  marketingTypeTwo: 'Communication Manager',
  marketingTypeThree: 'Role',
  WhatWeDo: 'What we do:',
  design: '01. Design',
  designDesc:
    'We design a project structure unique to your idea. Initiated by thorough research to understand the market and user, we create a sturdy and strategically justified base to build the digital solution for your needs.',
  develop: '02. Develop',
  developDesc:
    'Our team of highly skilled developers can create any solution from simple websites to complex blockchain technologies. By following our custom development process we can ensure an adaptable and bug-free product.',
  deliver: '03. Deliver',
  deliverDesc:
    'We deliver perfected projects. And our services don’t stop there. We maintain and update your website and application to keep them running smoothly and up to standards.',
};

const projectStrings = {
  HeaderOne: 'Previous work',
  projectNameOne: 'MRstudios',
  projectTypeOne: 'Web development',
  projectNameTwo: 'Bytboo',
  projectNameThree: 'Trakr',
  projectTypeThree: 'UI/UX design, Web development',
  projectNameFour: 'IMVI',
  projectTypeFour: 'UI/UX design, App development',
  projectNameFive: 'Morespace',
  projectTypeFive: 'Research for redesign & Wireframes',
  projectNameSix: 'RosholmDell',
  projectTypeSix: 'Design, Development',
  projectNameSeven: 'Foundi',
  projectNameEight: 'Prizbee',
  projectTypeEight: 'Development',
  projectNameNine: 'Brygghallen',
  projectNameTen: 'Gokaya',
  projectNameEleven: 'Timesheets',
  projectNameTweleve: 'SOOP',
  // projectNameTweleve: 'Comfoo',
  projectNameThirteen: 'Wellness Studio',
  projectNameFourteen: 'Q Games',
};

const servicesStrings = {
  headerOne: 'We’re a Scandinavian-based company',
  descpOne:
    '  - that creates websites and mobile applications that look and workexceptionally. By supplying holistic services within development & design, we have enabled startups and established companies into a new era of digitalization.',
  appDevelopmentHeader: '01. App development',
  appDevelopmentDescp:
    'We build and revamp mobile applications to inspire users, expand adaptation, and scale revenue. Vilje Tech works with your company to design, develop and deliver user-centric mobile apps. By having an iterative and thorough approach that encompasses strategy, research, UI/UX design, and front -and back-end development, we create something built to change the world.',
  appStepOne: '01. Backend',
  appStepTwo: '02. Frontend',
  appStepThree: '03. Testing',
  appStepFour: '04. Deploying',
  webDevelopmentHeader: '02. Web development',
  webDevelopmentDescp:
    'A good website establishes your business, communicates your brand identity and builds customer relationships. Vilje Tech develops great websites that deliver all that and much more. By understanding your customer, we deliver websites that enhance your brand, focus on user experience and function lawlessly.',
  uiDevelopmentHeader: '03. UX/UI design',
  uiDevelopmentDescp:
    'User experience (UX) and user interface (UI) design are defining factors for any digital product. Our design process involves understanding user needs through research, wireframing, high-fidelity prototyping, and iterating based on user/business feedback. We strive to create holistic experiences that satisfy business needs and solve end-user problems.',
  uiStepOne: '01. Empathize',
  uiStepTwo: '02.Define',
  uiStepThree: '03. Ideate',
  uiStepFour: '04. Prototype',
  uiStepFive: '05. Test',
  headerTwo: `We build intelligent digital solutions that empower startups and established companies to pursue their full potential, innovate with confidence, and achieve sustainable growth in today's dynamic market.`,
  chooseUsHeaderOne: 'We’re transparent',
  chooseUsDescpOne:
    'Throughout the project our main priority is to have clear and transparent communication between us and our clients.',
  chooseUsHeaderTwo: 'We’re reliable',
  chooseUsDescpTwo:
    'We understand your journey and always try to create a project journey that fits your one of a kind products.',
  chooseUsHeaderThree: 'We explore',
  chooseUsDescpThree:
    'For each new project we conduct client, branding and product-research to provide our clients with solutions proven to be adjusted to their unique market.',
  chooseUsHeaderFour: 'We’re creative',
  chooseUsDescpFour:
    'We are natural creatives that want to inspire our clients to go beyond the status quo in design and innovation.',
};

const contactStrings = {
  headerOne: 'Wanna say hi or have a project, we’d love to hear from you.',
  profileDataOne: 'Bharath Panyala',
  RoleOne: 'Chief Executive Officer, Vilje Tech',
  mailOne: 'bharath@viljetech.com',
  numOne: '072 44 30 170',
  profileDataTwo: 'Albin',
  RoleTwo: 'Sales Strategist, Vilje Tech',
  mailTwo: 'Albin@viljetech.com',
  numTwo: '072 44 30 170',
  thanksPopMsg:
    'Thank you for writing to us, our team will respond back as soon as possible. Cheers!',
  fullName: 'Full name*',
  emailAddress: 'Email address*',
  company: 'Company',
  tellUsAbout: 'Tell us about your project*',
  readAndAgree: 'I read and agree with Vilje Tech’s',
  privacyPolicy: 'privacy policy',
  sendMessage: 'Send message',
};

const blogStrings = {
  headerOne: 'Stories, news, and insights from our team.',
  noData: 'Data Not Found',
  all: 'All',
  blogs: 'Blogs',
  news: 'News',
};

const cookiesPopUp = {
  headerOne:
    'This site users functional cookies to gather insights and improve end-user experience.',
  cookiePolicy: 'Cookie policy',
  agree: 'Agree',
  decline: 'Decline',
};

const privacyPoliyStrings = {
  privacyPolicy: 'Privacy policy',
  headDesc: `We want to provide you with a safe and secure experience when visiting our website. You should never have to worry about your safety or privacy when using Vilje Tech solutions. To learn more about Vilje Tech's privacy policy, please see below.`,
  dataController: 'Data controller',
  dataControllerDescOne: `Vilje Tech AB (“Vilje Tech”, “we”, “us”, “our”), is a data controller of personal data (“Data”). At Vilje Tech, we take your privacy very seriously and ensure that all applicable data privacy regulations are observed. In this section, you can learn about how we process your Data when you use:- corresponding products and services offered by Vilje Tech (“Service”, “Services”, “Solutions”).`,
  dataControllerDescTwo: `This Policy does not apply to third-party applications, websites, products, services, or platforms that may be accessed through links that we provide to you. These third parties are owned and operated independently from us and have their own separate privacy and data-collection practices. Any personal data that you provide to these third parties will be governed by the third party’s own privacy policy. We cannot accept liability for the actions or policies of these independent third parties, and we are not responsible for the content or privacy practices of such sites.`,
  about: `About Vilje Tech`,
  aboutDescOne: `Vilje Tech is a design & development company that specializes in websites and applications. Vilje Tech is committed to providing customized solutions for our clients' digital needs. The team of developers has extensive experience in a variety of industries, from e-commerce and storage to healthcare and education. At Vilje Tech we specialize in UX and UI design when working with clients to ensure the quality of user experience and engagement.`,
  personal: `Categories of personal data`,
  personalDescOne: `Depending on your interaction with Vilje Tech, we may process different categories of your Data. When using our Site, the categories of Data that we may process include:`,
  personalDescTwo: [
    `- Name `,
    `- Email address`,
    `- Submitted video data`,
    `- Device-specific data (e.g. hardware model, operating system, and crash data)`,
    `- Telephone number`,
    `- Feedback, support, and error submissions`,
    `- Cookie and marketing preferences`,
  ],
  personalDescThree: `Additionally, when submitting feedback, support, or error forms, you may be asked to provide your name, contact information, feedback or error message, and the identification number of the product in question.`,
  personalDescFour: `This Data is collected when you register or use our Site, or Solutions, opt-in to cookies, opt-in to receiving marketing material, submit feedback or booking forms, from public records, and through direct correspondence between Vilje Tech and yourself.`,
  purpose: `Purpose`,
  purposeDescOne: `Vilje Tech processes your Data in order to be able to provide to you in completion our services in accordance with your wishes, to improve and expand our Solutions, for insight and research purposes, to communicate with you, to screen for potential risk or fraud, and, when in line with the preferences that you have indicated, to provide you with advertising or promotional material.`,
  purposeDescTwo: `As we develop insights and research, we ensure that data is aggregated in order to prevent any individual from being identified, in effect anonymising the data set. Our legal bases for processing are:`,
  purposeDescThree: [
    `- Based on your consent`,
    `- Necessary for the performance of contract`,
    `- Due to other legal obligations`,
    `- Legitimate interest`,
  ],
  dataRetention: `Data retention`,
  dataRetentionDescOne: `Unless required by law, Data is retained for up to 24 months after your last interaction with Vilje Tech, after which it is erased or anonymised. Interaction with Vilje Tech includes, for example, using our Site, or Services, or opening marketing communications from us.`,
  dataRetentionDescTwo: `You can at any time opt-out of receiving marketing communications or close your account by following on-screen instructions, or by contacting us`,
  dataRetentionDescTwoLink: ` bharath@viljetech.com`,
  employmentApplications: `Employment applications`,
  employmentApplicationsDescOne: `For both published vacancies and open applications, our recruitment process may involve the processing of the following categories of Data of our applicants: name; postal address; email address; telephone number; government-issued identification; references and CVs. This Data is retained for up to 5 years after the application date, or for as long as we have a legitimate business need for it. This Data is collected from public records, third-party recommendations and referrals, and through direct correspondence between Vilje Tech and yourself.`,
  thirdParties: `Third parties and Countries`,
  thirdPartiesDescOne: `We may share your Data with affiliated companies with which we have partnered in order to provide to you a greater set of services, and with trusted third-party service providers as necessary for them to perform services on our behalf, such as:`,

  thirdPartiesDescTwo: [
    `- Cloud data storage`,
    `- Processing payments`,
    `- Product development`,
    `- Application deployment and publication`,
    `- Cybersecurity`,
    `- Market research`,
    `- Serving advertisements and conducting surveys`,
    `- Communicating with you, such as by way of email or survey delivery`,
    `- Legal consultancy`,
    `- Customer relationship management, including social media`,
    `- Support and related services`,
  ],
  thirdPartiesDescThree: `As often as is appropriate and practical, third parties are only provided with aggregated data (through which individuals cannot be identified) and are obligated not to disclose or use the information for any purpose other than those listed above.`,
  thirdPartiesDescFour: `
  Vilje Tech uses servers located within the EU; however, if we transfer Data outside of the EU, we check that the third country meets the requirements of applicable data privacy legislation and make the necessary contractual arrangements to ensure third-party compliance. Vilje Tech does not sell your personal data.`,
  thirdPartiesDescFive: `For a full list of our third parties, please send your request to`,
  thirdPartiesDescLink: ` bharath@viljetech.com`,
  cookies: `Cookies`,
  cookiesDescOne: `A cookie is a small text file that the website or application that you are visiting requests to save on your device. They are widely used in order to make websites and applications work, or work more efficiently, as well as to provide information to the owners of the site or app.`,
  cookiesDescTwo: `
  Vilje Tech only uses essential cookies, those that allow our Site to work – as such, these cannot be disabled. Should Vilje Tech ever update its cookie collection for other processing purposes, we will first request your consent.`,
  cookiesDescThree: `PTS, the regulatory authority in the field, provides additional information about cookies on its website,`,
  cookiesDescThreeLink: ` pts.se`,
  cookiesDescFour: `For information on how to manage your browser’s cookie settings, follow these links:`,
  cookiesDescFive: [`- Chrome`, `- Internet Explorer`, `- Firefox`, `- Safari`],
  cybersecurity: `Cybersecurity`,
  cybersecurityDescOne: `Vilje Tech protects your Data using technical and organisational security measures. In order to prevent unauthorised access and ensure data accuracy, we have implemented strict guidelines for the organisation regarding the processing of personal data, including the use of two-factor authentication (2FA), SSL encryption, and trusted third-party cloud data storage, with servers located within the EU.`,
  yourRights: `Your rights`,
  yourRightsDescOne: `Under the EU General Data Protection Regulation (EU GDPR), you have the right to transparency in how your Data is processed. This means that you can at any time:`,
  yourRightsDescTwo: [
    `- Request an extract of what Data we process about you`,
    `- Request that we correct any Data that is incorrect`,
    `- Request that we erase your Data`,
    `- Request that we limit how your Data is used`,
    `- Object to the processing of your Data that we deem legitimate`,
    `- Request to have your Data transferred from Vilje Tech’s system to another body, i.e. data portability`,
    `- Revoke previously granted consent to any of our personal data processes`,
  ],
  yourRightsDescThree: `If you would like to request any of the above, please contact us`,
  yourRightsDescThreeLink: ` bharath@viljetech.com`,
  yourRightsDescFour: `You are entitled to contact Sweden’s data protection authority, Integritetsskyddsmyndigheten (IMY), to file a complaint if you are dissatisfied with how we process your Data.`,
  yourRightsDescFive: `More information about the EU GDPR can be found at `,
  yourRightsDescFiveLinkOne: ` imy.se `,
  yourRightsDescFiveLinkTwo: ` ec.europa.eu`,
  changesPrivacyPolicy: `Changes to Vilje Tech’s privacy policy`,
  changesPrivacyPolicyDescOne: `We may periodically update this Policy in order to accurately reflect changes to operations, or for regulatory reasons. When this happens, we will inform you by reasonable correspondence - for example, by email or on our Site.`,
  contactInformation: `Contact information`,
  contactInformationDescOne: `If you have questions about how Vilje Tech processes your personal data, or the contents of this Policy, you can at any time`,
  contactInformationDescOneLink: ` bharath@viljetech.com`,
};

const projectsCommonData = {
  servicesOfferd: 'Services offered:',
  overView: 'Overview:',
  yearOne: '© 2022',
  outCome: 'Outcome:',
  site: 'VIEW SITE',
  app: 'VIEW APP',
  technologiesBuild: 'Technologies used while building the product:',
  workInProgress: 'Work in progress.',
  sorry: ' Sorry,',
  upDatesSoon:
    'is an ongoing project and we will post the updates soon. Meantime, you can check out our other projects.',
};

// const miviProjectStrings = {
//   headerOne: 'IMVI',
//   yearOne: '2022',
//   servicesOfferdDescp:
//     'Redesign of the existing mobile app with improved user experience and app development.',
//   overViewDescp:
//     'IMVI approached our team to create a dyslexia treatment solution that could be used by dyslexic individuals of all ages and abilities. The solution was to be designed in Figma, built using React Native as the front-end framework and Firebase as the back-end platform.',
//   headerTwo:
//     'Poor eye coordination significantly impacts energy, headaches, concentration, double vision and reading speed.',
//   headerTwoDescpOne:
//     'To help improve the vision and reading skills of people, imvi Labs created a patented solution. They partnered up with Vilje Tech to turn theory into reality, an app that is straightforward & simple to use but has a complex coded core.',
//   headerTwoDescpTwo: `Imvi Lab's wanted a redesign for their VR-based vision training app with improved UX/UI and efficient coding for their complex solution. We offer a one-stop solution for all of their needs.`,
//   headerThree:
//     'To gather insights for our redesign, we conducted a quantitative research with the existing imvi mobile app: ',
//   headerThreePercentageOne: '90%',
//   headerThreePercDescpOne:
//     'of users felt the app is complex & lacks proper usage instructions',
//   headerThreePercentageTwo: '72%',
//   headerThreePercDescpTwo:
//     'of users voted the previous app as “Poor” in terms of end-user experience',
//   headerThreePercentageThree: '65%',
//   headerThreePercDescpThree:
//     'of existing user group felt there is no incentive to keep training on regular basis',
//   headerFour: 'Wireframes:',
//   headerFourDescpOne:
//     'Informed by the insights, we crafted wireframes which were further tested and shared with the client for nuanced feedback and validation of the design approach we were taking.',
//   headerFive: 'Designing a better mobile app experience for IMVI:',
//   headerFiveDescpOne:
//     'We made sure the final product is informational and requires minimal learning curve, both for guardians and training-users. We also came up with a step-wise questions for vergence test (previously designed as list) to eliminate the possibility of users skipping certain questions due to cluttered appearance of the list-view. ',
//   headerFiveDescpTwo:
//     'We felt notifications alone won’t give enough incentive for users to keep training on a regular basis, so we designed “Goals” structure where users can pick a weekly goal and can monitor their progress.',
// };
const projectsData = [
  {
    id: 1,
    title: 'IMVI',
    headerText: 'IMVI',
    bannerImage: imviOg,
    year: '2022',
    flagImg: '',
    servicesOffered:
      'Redesign of the existing mobile app with improved user experience and app development.',
    overview:
      'IMVI approached our team to create a dyslexia treatment solution that could be used by dyslexic individuals of all ages and abilities. The solution was to be designed in Figma, built using React Native as the front-end framework and Firebase as the back-end platform.',
    appLink: '',
    webLink: '',
    section2: {
      image: imvi2,
      headText:
        'Poor eye coordination significantly impacts energy, headaches, concentration, double vision and reading speed.',
      desc: 'To help improve the vision and reading skills of people, imvi Labs created a patented solution. They partnered up with Vilje Tech to turn theory into reality, an app that is straightforward & simple to use but has a complex coded core.',
      desc2: `Imvi Lab's wanted a redesign for their VR-based vision training app with improved UX/UI and efficient coding for their complex solution. We offer a one-stop solution for all of their needs.`,
    },
    reasearchResult: {
      images: [imvi3],
      headText:
        'To gather insights for our redesign, we conducted a quantitative research with the existing imvi mobile app: ',
      results: [
        {
          percent: '90%',
          desc: 'of users felt the app is complex & lacks proper usage instructions',
        },
        {
          percent: '72%',
          desc: 'of users voted the previous app as “Poor” in terms of end-user experience',
        },
        {
          percent: '65%',
          desc: 'of existing user group felt there is no incentive to keep training on regular basis',
        },
      ],
    },
    wireFrames: {
      headText: 'Wireframes:',
      images: [imviWireframes],
      desc: 'Informed by the insights, we crafted wireframes which were further tested and shared with the client for nuanced feedback and validation of the design approach we were taking.',
    },
    imagesSection: {
      headText: 'Designing a better mobile app experience for IMVI:',
      images: [imviHifi1, imviHifi2, imviHifi3],
      desc: 'We made sure the final product is informational and requires minimal learning curve, both for guardians and training-users. We also came up with a step-wise questions for vergence test (previously designed as list) to eliminate the possibility of users skipping certain questions due to cluttered appearance of the list-view. ',
      desc2:
        'We felt notifications alone won’t give enough incentive for users to keep training on a regular basis, so we designed “Goals” structure where users can pick a weekly goal and can monitor their progress.',
    },
    developingProcess: {
      head: 'Developing the app:',
      steps: [
        {
          name: 'Frontend',
          desc: 'We designed the user interface to be simple and intuitive, with clear instructions and feedback.',
        },
        {
          name: 'Backend',
          desc: 'Firebase was used to store user data and provide real-time updates to the front-end.',
        },
        {
          name: 'Test & Deploy',
          desc: 'The solution underwent extensive testing before deployment, including usability testing with dyslexic individuals.',
        },
        {
          name: 'Maintenance',
          desc: 'We used modern and standardized technologies for the solution to ensure it was easily maintainable.',
        },
      ],
    },
    outcome:
      'Thanks to our partnership with imvi Labs, we were able to create an app that has helped improve the vision and reading skills of people.',
    testimonials: {
      desc: 'Vilje Tech built our complex eye coordination training app and streamlined development efficiently and qualitatively from MVP to a complete mobile application. Our unique solution had several specially built functions which Vilje Tech developed. They had clear and transparent communication and were highly involved throughout the project; we will continue working together. We highly recommend Vilje Tech.',
      name: 'Michael Malmqvist',
      companyName: 'CIO, IMVI Labs, Sweden',
      image: testimonialSampleProfile,
    },
    technologiesUSed: ['1. React Native', '2. Firebase', '3. Figma'],
  },
  {
    id: 2,
    title: 'Bytboo',
    headerText: 'Bytboo',
    bannerImage: bytBooOg,
    flagImg: '',
    year: '2022',
    servicesOffered: 'Web development (Frontend).',
    overview: `Bytboo is a real estate estimation project in Sweden that provides users with an easy and reliable way to estimate the value of their property. The project involved a variety of development processes, including frontend development, architecture, testing, and delivery.`,
    appLink: '',
    webLink: 'https://bytboo.com/',
    section2: {
      image: bytBoo2,
      headText:
        'The idea of Bytboo emerged from the realisation that the real estate industry has nearly no transparency.',
      desc: 'After extensive research, it was clear that the choice of a realtor affects the price when selling a home. The solution was creating a service where users compare realtors, explore sales statistics and get economic insights within the housing market. ',
      desc2:
        'Bytboo contacted Vilje Tech to develop their vision. They wanted a design with complex animations with click events, data handling and dynamic images with different colours. Challenges that we solved. The result was a website that allows for a seamless experience for the user while having high standards for function and quality.',
    },
    // reasearchResult: {
    //   image: '',
    //   headText:
    //     'To gather insights for our redesign, we conducted a quantitative research with the existing imvi mobile app: ',
    //   results: [
    //     {
    //       percent: '90%',
    //       desc: 'of users felt the app is complex & lacks proper usage instructions',
    //     },
    //     {
    //       percent: '72%',
    //       desc: 'of users voted the previous app as “Poor” in terms of end-user experience',
    //     },
    //     {
    //       percent: '65%',
    //       desc: 'of existing user group felt there is no incentive to keep training on regular basis',
    //     },
    //   ],
    // },
    // wireFrames: {
    //   headText: 'Wireframes:',
    //   images: [],
    //   desc: 'Informed by the insights, we crafted wireframes which were further tested and shared with the client for nuanced feedback and validation of the design approach we were taking.',
    // },
    // imagesSection: {
    // headText: 'Designing a better mobile app experience for IMVI:',
    // images: [bytBooHifi1, bytBooHifi2],
    // desc: '',
    // desc2: '',
    // },
    developingProcess: {
      head: 'Developing the app:',
      steps: [
        {
          name: 'Frontend',
          desc: 'Modern frontend technologies like React and TypeScript were used to create a simple and intuitive design that was responsive on desktop and mobile. The team also implemented features like auto-suggestion and real-time updating.',
        },
        {
          name: 'Architecture',
          desc: `Bytboo's architecture was designed for scalability and maintainability, using microservices to create a modular system. RESTful API architecture allowed seamless communication between frontend and backend.`,
        },
        {
          name: 'Testing',
          desc: `Different methodologies like unit testing, integration testing, end-to-end testing, and security testing were used to ensure the quality of the app.`,
        },
        {
          name: 'Delivery',
          desc: `The delivery process of Bytboo involved deploying the app to a production environment and ensuring that it was available to users. Continuous integration and continuous delivery technique is used so that the app was deployed quickly and efficiently.`,
        },
      ],
      images: [bytBooHifi1, bytBooHifi2],
    },
    outcome: `We delivered a clean and engaging end product that packaged the Bytboo team's vision perfectly. We are proud to be apart of a more transparent real estate market in Sweden.`,
    testimonials: {
      desc: `Vilje Tech was in charge of building Bytboo's frontend when we rebuild the product from scratch. I am very happy with their work. Continuous updates along the way, pragmatic thinking presenting solutions to any obstacles we faced. The end result was spotless. I can definitely recommend Vilje Tech to any company in the same situation as Bytboo.`,
      name: 'Herman Treschow',
      companyName: 'CEO, Bytboo AB',
      image: testimonialSampleProfile,
    },
    technologiesUSed: ['1. React JS'],
  },
  {
    id: 3,
    title: 'MRstudios',
    headerText: 'MRstudios',
    bannerImage: mrStudioOg,
    flagImg: '',
    year: '2023',
    servicesOffered: 'Web development (Frontend & Backend).',
    overview: `Embrace a world of endless possibilities with MRstuidos, a true leader in the realm of VR & AR. We infuse genuine optimism into every pixel, crafting immersive interactive videos and dynamic 3D models that turn your dreams into digital realities, all while achieving unprecedented loading speeds.`,
    appLink: '',
    webLink: 'https://mrstudios.eu/',
    section2: {
      image: mrStudio2,
      headText:
        'Guiding the future: Where VR & AR dreams come true with MRstuidos.',
      desc: `Faced with a client's outdated Wordpress website, MRstuidos saw an opportunity to create genuine change. Empowered by Figma files and a positive outlook, we embarked on a journey to rejuvenate their online presence, ensuring that innovation and excellence were at the heart of every choice`,
      desc2: `Drawing on our expertise in web development and design, we set out to transform the client's digital platform. With meticulous dedication, we seamlessly integrated interactive videos and captivating 3D models, pushing technological boundaries to achieve a loading speed of just 0.5 seconds – a remarkable leap from the previous 2.5 minutes. This genuine commitment to excellence extended to achieving an industry-leading 99% performance rating, alongside an impressive 99% in best practices and a heartening 95% in SEO.`,
    },
    reasearchResult: {
      title: 'Developing MRstudios’ website:',
      images: [mrStudioHifi1, mrStudioHifi2],
      headText:
        'Our tenacious commitment to excellence while developing translated into a website that is snappy and efficient:',
      results: [
        {
          percent: '99%',
          desc: 'We increased the Page Performance from 62% to astonishing 99%',
        },
        {
          percent: '100%',
          desc: 'Newly developed WordPress site has scored 100% in terms of best practices ',
        },
        {
          percent: '0.3 secs',
          desc: 'We made a big leap in loading speeds, reducing it from 4 secs to blazing 0.3 secs',
        },
      ],
    },
    // wireFrames: {
    //   headText: 'Wireframes:',
    //   images: [imviWireframes],
    //   desc: 'Informed by the insights, we crafted wireframes which were further tested and shared with the client for nuanced feedback and validation of the design approach we were taking.',
    // },
    // imagesSection: {
    //   headText: 'Developing MRstudio’s website:',
    //   images: [mrStudioHifi1, mrStudioHifi2],
    //   desc: '',
    //   desc2: '',
    // },
    // developingProcess: {
    //   head: 'Developing the app:',
    //   steps: [
    //     {
    //       name: 'Frontend',
    //       desc: 'We designed the user interface to be simple and intuitive, with clear instructions and feedback.',
    //     },
    //     {
    //       name: 'Backend',
    //       desc: 'Firebase was used to store user data and provide real-time updates to the front-end.',
    //     },
    //     {
    //       name: 'Test & Deploy',
    //       desc: 'The solution underwent extensive testing before deployment, including usability testing with dyslexic individuals.',
    //     },
    //     {
    //       name: 'Maintenance',
    //       desc: 'We used modern and standardized technologies for the solution to ensure it was easily maintainable.',
    //     },
    //   ],
    // },
    outcome: `MRstudios’ website underwent a true metamorphosis, with loading speeds reduced to a fraction of their former time. This achievement, coupled with our unwavering commitment to best practices and SEO, not only transformed their online presence but also positioned MRstuidos as a beacon of optimism and innovation in the VR & AR landscape. Join us on a journey where every pixel holds the promise of a brighter digital future.`,
    testimonials: {
      desc: `Vilje Tech has truly excelled in the reconstruction of our pre-existing website using our Figma design files. The scope of their work encompassed the frontend of our website, which was built in WordPress and Elementor. While the process and project management had occasional challenges as it was a slightly unconventional process for both parties, the end result has undeniably surpassed our expectations. The improved speed and responsiveness of our website are impressive, which has resulted in a high-performance website.`,
      name: 'Annemiek de Nijs',
      companyName: 'Marketing Manager, MRstudios',
      image: testimonialSampleProfile,
    },
    technologiesUSed: ['1. WordPress', '2. Elementor', '3. Figma'],
  },
  {
    id: 4,
    title: 'Morespace',
    headerText: 'Morespace',
    bannerImage: moreSpaceOg,
    flagImg: '',
    year: '2023',
    servicesOffered:
      'Research and crafting wireframes or skeleton for the redesign.',
    overview: `Morespace is a British-owned ultra modern storage facility located in Dubai. They make self-storage easy for both businesses and individuals. The project is about researching how the current site can be improved and crafting wireframes for a redesign of the existing site. `,
    appLink: '',
    webLink: '',
    section2: {
      image: moreSpace2,
      headText:
        'Redefining the website experience of Morespace’s self-storage solution.',
      desc: `Morespace approached us to reimagine their existing website, from user flow, and UX, to visual interfaces. Redundant sections across pages, outdated layouts are main issues with the existing site.`,
      desc2: `We started the project with redefining the information architecture to optimise the user flows, add new features/pages, and inform our choices for the redesign.`,
    },
    // reasearchResult: {
    //   image: imvi3,
    //   headText:
    //     'To gather insights for our redesign, we conducted a quantitative research with the existing imvi mobile app: ',
    //   results: [
    //     {
    //       percent: '90%',
    //       desc: 'of users felt the app is complex & lacks proper usage instructions',
    //     },
    //     {
    //       percent: '72%',
    //       desc: 'of users voted the previous app as “Poor” in terms of end-user experience',
    //     },
    //     {
    //       percent: '65%',
    //       desc: 'of existing user group felt there is no incentive to keep training on regular basis',
    //     },
    //   ],
    // },
    wireFrames: {
      headText: 'Designing wireframes:',
      images: [moreSpaceHifi1, moreSpaceHifi2, moreSpaceHifi3, moreSpaceHifi4],
      desc: `We improved the booking experience by allowing users to see how many items will fit in a selected unit. We also included a "Help me choose" feature to recommend the correct unit space for individuals/businesses depending on their storage needs.`,
      desc2: `An extra "Profile page" is added to help subscribed individuals/businesses to manage their booked units and payments, upgrade/degrade the storage unit space, and much more.`,
    },
    // imagesSection: {
    //   headText: 'Designing a better mobile app experience for IMVI:',
    //   images: [imviHifi1, imviHifi2, imviHifi3],
    //   desc: '',
    //   desc2: '',
    // },
    // developingProcess: {
    //   head: 'Developing the app:',
    //   steps: [
    //     {
    //       name: 'Frontend',
    //       desc: 'We designed the user interface to be simple and intuitive, with clear instructions and feedback.',
    //     },
    //     {
    //       name: 'Backend',
    //       desc: 'Firebase was used to store user data and provide real-time updates to the front-end.',
    //     },
    //     {
    //       name: 'Test & Deploy',
    //       desc: 'The solution underwent extensive testing before deployment, including usability testing with dyslexic individuals.',
    //     },
    //     {
    //       name: 'Maintenance',
    //       desc: 'We used modern and standardized technologies for the solution to ensure it was easily maintainable.',
    //     },
    //   ],
    // },
    outcome: `Apart from redefining Morespace’s site map, we also delivered wireframes that are clean, user-friendly, and elevate the site’s overall online experience. `,
    testimonials: {
      desc: `Collaborating with Vilje Tech on the UX design for our ‘Morespace’ website was a revelation. Their clean, intuitive design perfectly captures our website’s essence. They transformed our vision into reality, delivering a user-friendly experience that exceeded our expectations. The attention to detail and dedication to quality shine through in every aspect of their work. We’re thrilled with the outcome and highly recommend Vilje for their expertise and professionalism.`,
      name: 'Danieal Zehadi',
      companyName: 'Morespace Project Lead',
      image: testimonialSampleProfile,
    },
    technologiesUSed: ['1. Figma'],
  },
  {
    id: 5,
    title: 'Trakr',
    headerText: 'Trakr',
    bannerImage: trakrOg,
    flagImg: '',
    year: '2023',
    servicesOffered: `UX/UI design, Web design, Web development, Software design, Software development.`,
    overview: `Integrating QR codes and RFID for streamlined asset management: efficient check-in/out, maintenance tracking, and lost item recovery. Enhancing control, minimizing downtimes, and optimizing asset value throughout the lifecycle. A new era of comprehensive asset management begins.`,
    appLink: '',
    webLink: 'http://trakr.se',
    section2: {
      image: trakr2,
      headText: `Elevating asset control and efficiency with seamless QR code and RFID solutions.`,
      desc: `Emerging from the complexities of modern asset management, our idea is to fuse QR codes and RFID technology, a response to the demand for streamlined tracking, maintenance, and recovery solutions. This innovation leverages digital identification, aiming to revolutionize asset management into a proactive, value-optimized process, reducing disruptions.`,
      desc2: `By amalgamating QR codes and RFID technology, we propose a dynamic approach to elevate asset management. This solution enables seamless check-in/out procedures, empowers proactive maintenance control, and facilitates swift recovery of misplaced items. The outcome is an enhanced asset management framework, minimizing disruptions and unlocking full lifecycle value.`,
    },
    section3: {
      headText: `Creating presentation deck for our unique asset management solution:`,
      images: [bannerImg6],
    },
    // reasearchResult: {
    //   image: imvi3,
    //   headText:
    //     'To gather insights for our redesign, we conducted a quantitative research with the existing imvi mobile app: ',
    //   results: [
    //     {
    //       percent: '90%',
    //       desc: 'of users felt the app is complex & lacks proper usage instructions',
    //     },
    //     {
    //       percent: '72%',
    //       desc: 'of users voted the previous app as “Poor” in terms of end-user experience',
    //     },
    //     {
    //       percent: '65%',
    //       desc: 'of existing user group felt there is no incentive to keep training on regular basis',
    //     },
    //   ],
    // },
    wireFrames: {
      headText: 'Designing and Developing trakr’s landing page:',
      images: [trakrHifi1, trakrHifi2],
      desc: '',
    },
    section4: {
      headText: `Transforming complex ideas into simpler user flows:`,
      images: [trakrHifi3],
    },
    imagesSection: {
      headText:
        'Building trakr’s software interfaces for both admins and users:',
      images: [trakrHifi4, trakrHifi5, trakrHifi6, trakrHifi7],
      desc: '',
      desc2: '',
      outcome:
        'Trakr, our in-house project, is still in the early stages of its design and development cycle. We are planning to run scenario-based field-tests in collaboration with Hetch, starting this November.',
      technologiesUSed: ['1. Figma', '2. MongoDB', '3. AWS', '4. React JS'],
    },
    // developingProcess: {
    //   head: 'Developing the app:',
    //   steps: [
    //     {
    //       name: 'Frontend',
    //       desc: 'We designed the user interface to be simple and intuitive, with clear instructions and feedback.',
    //     },
    //     {
    //       name: 'Backend',
    //       desc: 'Firebase was used to store user data and provide real-time updates to the front-end.',
    //     },
    //     {
    //       name: 'Test & Deploy',
    //       desc: 'The solution underwent extensive testing before deployment, including usability testing with dyslexic individuals.',
    //     },
    //     {
    //       name: 'Maintenance',
    //       desc: 'We used modern and standardized technologies for the solution to ensure it was easily maintainable.',
    //     },
    //   ],
    // },
    // outcome:
    //   'Thanks to our partnership with imvi Labs, we were able to create an app that has helped improve the vision and reading skills of people.',
    // testimonials: {
    //   desc: 'Vilje Tech built our complex eye coordination training app and streamlined development efficiently and qualitatively from MVP to a complete mobile application. Our unique solution had several specially built functions which Vilje Tech developed. They had clear and transparent communication and were highly involved throughout the project; we will continue working together. We highly recommend Vilje Tech.',
    //   name: 'Michael Malmqvist',
    //   companyName: 'CIO, IMVI Labs, Sweden',
    //   image: '',
    // },
    // technologiesUSed: ['1. React Native', '2. Firebase', '3. Figma'],
  },
  {
    id: 6,
    title: 'Foundi',
    headerText: 'Foundi',
    bannerImage: foundiOg,
    flagImg: '',
    year: '2022',
    servicesOffered: `App design, App development (Frontend & Backend), Web design, and Web development (Frontend & Backend).`,
    overview: `Foundi is a QR code-based lost and found solution that prioritizes user privacy while providing a user-friendly experience. The project involves research, ideation, design, development, and maintenance to create a reliable and effective solution for lost and found items.`,
    appLink: '',
    webLink: '',
    section2: {
      image: foundi2,
      headText: `Creating the new standard for lost and found solutions.`,
      desc: `Lost something recently? Unfortunate but common, but we tend to lose our things. Foundi's studies show that 96% of the Swedish population loses at least one valuable item a year. Stressful and annoying, but there is an easy solution. Together we developed a strong brand identity and explored the most unique and effective solution. The result was a QR code based solution that connects the user to their possessions.`,
      desc2: `Foundi needed a team to help them turn their vision into reality from start to finish. Their idea inspired us at Vilje Tech. The project started with brand identity and creating the app's and website's architecture and wireframes. To maximise traction and optimise the user experience, our copywriters and designers worked together to turn Foundi into a unique experience. Our collaboration is still ongoing, and we will follow Foundi into the launch and look forward to seeing them grow.`,
    },
    // section3: {
    //   headText: `Designing the UX/UI for the Foundi mobile application:`,
    //   images: [foundiHifi1, foundiHifi2, foundiHifi3],
    // },
    // reasearchResult: {
    //   image: imvi3,
    //   headText:
    //     'To gather insights for our redesign, we conducted a quantitative research with the existing imvi mobile app: ',
    //   results: [
    //     {
    //       percent: '90%',
    //       desc: 'of users felt the app is complex & lacks proper usage instructions',
    //     },
    //     {
    //       percent: '72%',
    //       desc: 'of users voted the previous app as “Poor” in terms of end-user experience',
    //     },
    //     {
    //       percent: '65%',
    //       desc: 'of existing user group felt there is no incentive to keep training on regular basis',
    //     },
    //   ],
    // },
    wireFrames: {
      headText: `Designing the UX/UI for the Foundi mobile application:`,
      images: [foundiHifi1, foundiHifi2, foundiHifi3],
      desc: '',
    },
    // section4: {
    //   headText: `Transforming complex ideas into simpler user flows:`,
    //   images: [trakrHifi3],
    // },
    imagesSection: {
      headText: `Designing and Developing Foundi’s website:`,
      images: [foundiHifi4, foundiHifi5],
      desc: '',
      desc2: '',
      outcome: `Our collaboration with Foundi is still ongoing and we are looking forward to product’s launch and will be growing with them along the way.`,
      technologiesUSed: [
        '1. Figma',
        '2. React Native',
        '3. MongoDB',
        '4. AWS',
        '5. React JS',
      ],
    },
    // developingProcess: {
    //   head: 'Developing the app:',
    //   steps: [
    //     {
    //       name: 'Frontend',
    //       desc: 'We designed the user interface to be simple and intuitive, with clear instructions and feedback.',
    //     },
    //     {
    //       name: 'Backend',
    //       desc: 'Firebase was used to store user data and provide real-time updates to the front-end.',
    //     },
    //     {
    //       name: 'Test & Deploy',
    //       desc: 'The solution underwent extensive testing before deployment, including usability testing with dyslexic individuals.',
    //     },
    //     {
    //       name: 'Maintenance',
    //       desc: 'We used modern and standardized technologies for the solution to ensure it was easily maintainable.',
    //     },
    //   ],
    // },
    // outcome:
    //   'Thanks to our partnership with imvi Labs, we were able to create an app that has helped improve the vision and reading skills of people.',
    // testimonials: {
    //   desc: 'Vilje Tech built our complex eye coordination training app and streamlined development efficiently and qualitatively from MVP to a complete mobile application. Our unique solution had several specially built functions which Vilje Tech developed. They had clear and transparent communication and were highly involved throughout the project; we will continue working together. We highly recommend Vilje Tech.',
    //   name: 'Michael Malmqvist',
    //   companyName: 'CIO, IMVI Labs, Sweden',
    //   image: '',
    // },
    // technologiesUSed: ['1. React Native', '2. Firebase', '3. Figma'],
  },
  {
    id: 7,
    title: 'RosholmDell',
    headerText: 'RosholmDell',
    bannerImage: rdlaOg,
    flagImg: '',
    year: '2023',
    servicesOffered: `Redesign of the existing website with improved UX/UI and Web development.`,
    overview: `RosholmDell’s journey encompassed a comprehensive digital transformation, facilitated by our team. We seamlessly combined technology, design, and functionality to redefine and elevate the essence of legal services.`,
    appLink: '',
    webLink: 'https://www.rosholmdell.se/',
    section2: {
      image: rdlaHifi1,
      headText: `Focusing on delivering high-quality Useful Legal Services.`,
      desc: `RosholmDell, a prominent legal firm, embarked on a transformative journey to reshape its digital presence and enhance the accessibility of its legal services. Committed to staying at the forefront of the legal industry, RosholmDell recognized the need for a comprehensive digital overhaul to meet the evolving demands of its clients and stakeholders.`,
      desc2: `In pursuit of this digital transformation, RosholmDell engaged our team to spearhead the entire redesign and development of their digital assets. This ambitious project aimed not only to revamp the firm's online identity but also to reimagine the way legal services were delivered and accessed in the digital age.`,
    },
    section3: {
      headText: `Optimising the site by reimaging the information architecture:`,
      images: [rdlaHifi2],
    },
    // reasearchResult: {
    //   image: imvi3,
    //   headText:
    //     'To gather insights for our redesign, we conducted a quantitative research with the existing imvi mobile app: ',
    //   results: [
    //     {
    //       percent: '90%',
    //       desc: 'of users felt the app is complex & lacks proper usage instructions',
    //     },
    //     {
    //       percent: '72%',
    //       desc: 'of users voted the previous app as “Poor” in terms of end-user experience',
    //     },
    //     {
    //       percent: '65%',
    //       desc: 'of existing user group felt there is no incentive to keep training on regular basis',
    //     },
    //   ],
    // },
    wireFrames: {
      headText: `Redesigning and Developing RosholmDell’s online presence:`,
      images: [rdlaHifi3, rdlaHifi4, rdlaHifi5, rdlaHifi6],
      desc: '',
    },
    // section4: {
    //   headText: `Transforming complex ideas into simpler user flows:`,
    //   images: [trakrHifi3],
    // },
    // imagesSection: {
    //   headText: `Designing and Developing Foundi’s website:`,
    //   images: [foundiHifi4, foundiHifi5],
    //   desc: '',
    //   desc2: '',
    //   outcome: `Our collaboration with Foundi is still ongoing and we are looking forward to product’s launch and will be growing with them along the way.`,
    //   technologiesUSed: [
    //     '1. Figma',
    //     '2. React Native',
    //     '3. MongoDB',
    //     '4. AWS',
    //     '5. React JS',
    //   ],
    // },
    // developingProcess: {
    //   head: 'Developing the app:',
    //   steps: [
    //     {
    //       name: 'Frontend',
    //       desc: 'We designed the user interface to be simple and intuitive, with clear instructions and feedback.',
    //     },
    //     {
    //       name: 'Backend',
    //       desc: 'Firebase was used to store user data and provide real-time updates to the front-end.',
    //     },
    //     {
    //       name: 'Test & Deploy',
    //       desc: 'The solution underwent extensive testing before deployment, including usability testing with dyslexic individuals.',
    //     },
    //     {
    //       name: 'Maintenance',
    //       desc: 'We used modern and standardized technologies for the solution to ensure it was easily maintainable.',
    //     },
    //   ],
    // },
    outcome: `The culmination of this partnership is a digital ecosystem that reflects RosholmDell's unwavering commitment to excellence. The new design seamlessly blends aesthetics and functionality, creating an inviting and intuitive platform for clients. The streamlined user experience enhances efficiency, making it easier for clients to access legal resources and interact with RDlaw. The transformation serves as a beacon of their dedication to providing top-tier legal services in a rapidly evolving digital landscape, and we are privileged to have been part of this exciting journey.`,
    testimonials: {
      desc: `Our partnership with Vilje tech for our digital transformation was nothing short of exceptional. Their expertise in redesign and development has reshaped our digital identity and greatly improved our online services. The entire process was seamless, from meticulous background research to a flawless execution of the redesign and development. The result is a user-friendly and visually appealing platform that has elevated our clients' digital experience. We appreciate Vilje tech's dedication and contribution to our digital evolution, positioning us as leaders in the legal industry's digital landscape.`,
      name: 'Jonas Axelson',
      companyName: 'CEO of RosholmDell',
      image: testimonialSampleProfile,
    },
    technologiesUSed: ['1. Figma', '2. MongoDB', '3. AWS', '4. React JS'],
  },

  {
    id: 8,
    title: 'Gokaya',
    headerText: 'Gokaya',
    bannerImage: gokayaOg,
    year: '2022',
    flagImg: '',
    servicesOffered: 'Web development (Frontend).',
    overview:
      'Gokaya is at the forefront of innovation, pioneering unmanned rentals that simplify and enhance the rental process. Our comprehensive solution, which combines an intuitive booking system with cutting-edge digital padlocks and versatile key boxes, ensures a seamless and efficient experience for both providers and renters.',
    webLink: '',
    section2: {
      image: gokayaHifi1,
      headText: `Leading the future of rentals: Gokaya's unmanned revolution for effortless access.`,
      desc: `Gokaya is at the forefront of innovation, pioneering unmanned rentals that simplify and enhance the rental process. Our comprehensive solution, which combines an intuitive booking system with cutting-edge digital padlocks and versatile key boxes, ensures a seamless and efficient experience for both providers and renters.`,
      desc2: `Harnessing the power of React JS for our frontend development, Gokaya ensures a user interface that's not only visually appealing but also highly responsive and engaging. This strategic use of technology ensures that the interface mirrors the innovation and user-centric approach that defines our unmanned rental solution.`,
    },
    // reasearchResult: {
    //   images: [imvi3],
    //   headText:
    //     'To gather insights for our redesign, we conducted a quantitative research with the existing imvi mobile app: ',
    //   results: [
    //     {
    //       percent: '90%',
    //       desc: 'of users felt the app is complex & lacks proper usage instructions',
    //     },
    //     {
    //       percent: '72%',
    //       desc: 'of users voted the previous app as “Poor” in terms of end-user experience',
    //     },
    //     {
    //       percent: '65%',
    //       desc: 'of existing user group felt there is no incentive to keep training on regular basis',
    //     },
    //   ],
    // },
    // wireFrames: {
    //   headText: 'Wireframes:',
    //   images: [imviWireframes],
    //   desc: 'Informed by the insights, we crafted wireframes which were further tested and shared with the client for nuanced feedback and validation of the design approach we were taking.',
    // },
    // imagesSection: {
    //   headText: 'Designing a better mobile app experience for IMVI:',
    //   images: [imviHifi1, imviHifi2, imviHifi3],
    //   desc: 'We made sure the final product is informational and requires minimal learning curve, both for guardians and training-users. We also came up with a step-wise questions for vergence test (previously designed as list) to eliminate the possibility of users skipping certain questions due to cluttered appearance of the list-view. ',
    //   desc2:
    //     'We felt notifications alone won’t give enough incentive for users to keep training on a regular basis, so we designed “Goals” structure where users can pick a weekly goal and can monitor their progress.',
    // },
    // developingProcess: {
    //   head: 'Developing the app:',
    //   steps: [
    //     {
    //       name: 'Frontend',
    //       desc: 'We designed the user interface to be simple and intuitive, with clear instructions and feedback.',
    //     },
    //     {
    //       name: 'Backend',
    //       desc: 'Firebase was used to store user data and provide real-time updates to the front-end.',
    //     },
    //     {
    //       name: 'Test & Deploy',
    //       desc: 'The solution underwent extensive testing before deployment, including usability testing with dyslexic individuals.',
    //     },
    //     {
    //       name: 'Maintenance',
    //       desc: 'We used modern and standardized technologies for the solution to ensure it was easily maintainable.',
    //     },
    //   ],
    // },
    outcome: `Gokaya's visionary approach to unmanned rentals sets a new standard for convenience and accessibility. By integrating advanced booking technology, digital padlocks, and versatile key boxes, Gokaya empowers users to effortlessly access a wide range of products. The utilization of React JS for the frontend elevates the overall experience, creating an interface that's as advanced and forward-thinking as the solution itself. Gokaya's unwavering commitment to innovation paves the way for a future where unmanned rentals seamlessly blend technology with ease, offering a genuinely optimistic and convenient way to access the products you need.`,
    testimonials: {
      desc: `Their proficiency in developing a seamless React JS frontend for our Unmanned Rental solution was nothing short of exceptional. What set them apart was not just their technical prowess, but their ability to truly understand our vision and translate it into a user-friendly interface that perfectly complements our innovative concept. Throughout the project, their attention to detail was remarkable, and their unwavering commitment to quality was evident in every interaction. Thanks to Vilje Tech's expertise, Gokaya's Unmanned Rental solution now stands as a beacon of convenience and accessibility. We wholeheartedly recommend Vilje Tech for their professionalism and excellence.`,
      name: 'Emanuel',
      companyName: 'CTO, Gokaya, Sweden',
      image: testimonialSampleProfile,
    },
    technologiesUSed: ['1. React JS'],
  },

  {
    id: 9,
    title: 'Brygghallen',
    headerText: 'Brygghallen',
    bannerImage: bryggOg,
    year: '2022',
    flagImg: '',
    servicesOffered: 'Web development (Frontend & Backend).',
    overview:
      'Step into a realm of brewing mastery at Brygghallen in Höganäs, where beer enthusiasts are invited to a unique and captivating experience. In collaboration with Thorn Creative, Brygghallen has emerged as a haven for those who seek the finest in brewing excellence, seamlessly integrating modern technology into its charm.',
    webLink: 'https://tc.viljetech.com/',
    section2: {
      image: bryggHifi1,
      headText: `Where brewing art unites: Brygghallen - a fusion of beer, food, and community.`,
      desc: `Brygghallen is a testament to visionary partnership. Guided by Thorn Creative's creative brilliance, Viken Bryggeri and Kullens Bryggeri have come together to create a haven for brewing aficionados. In the digital realm, Brygghallen's virtual presence was meticulously crafted, designed to reflect the essence of its physical counterpart.`,
      desc2: `The digital transformation of Brygghallen was brought to life through the expert touch of Thorn Creative. Utilizing Thorn Creative's Figma design files, a seamless WordPress frontend and backend were meticulously constructed. The result is a digital experience that seamlessly empowers even non-technical individuals at the restaurant to effortlessly manage their online presence. Thorn Creative's design acumen seamlessly merged with modern technology, resulting in an intuitive and engaging platform.`,
    },
    // reasearchResult: {
    //   images: [imvi3],
    //   headText:
    //     'To gather insights for our redesign, we conducted a quantitative research with the existing imvi mobile app: ',
    //   results: [
    //     {
    //       percent: '90%',
    //       desc: 'of users felt the app is complex & lacks proper usage instructions',
    //     },
    //     {
    //       percent: '72%',
    //       desc: 'of users voted the previous app as “Poor” in terms of end-user experience',
    //     },
    //     {
    //       percent: '65%',
    //       desc: 'of existing user group felt there is no incentive to keep training on regular basis',
    //     },
    //   ],
    // },
    wireFrames: {
      headText: 'Developing the website:',
      images: [bryggHifi2],
      desc: ``,
    },
    // imagesSection: {
    //   headText: 'Designing a better mobile app experience for IMVI:',
    //   images: [imviHifi1, imviHifi2, imviHifi3],
    //   desc: 'We made sure the final product is informational and requires minimal learning curve, both for guardians and training-users. We also came up with a step-wise questions for vergence test (previously designed as list) to eliminate the possibility of users skipping certain questions due to cluttered appearance of the list-view. ',
    //   desc2:
    //     'We felt notifications alone won’t give enough incentive for users to keep training on a regular basis, so we designed “Goals” structure where users can pick a weekly goal and can monitor their progress.',
    // },
    // developingProcess: {
    //   head: 'Developing the app:',
    //   steps: [
    //     {
    //       name: 'Frontend',
    //       desc: 'We designed the user interface to be simple and intuitive, with clear instructions and feedback.',
    //     },
    //     {
    //       name: 'Backend',
    //       desc: 'Firebase was used to store user data and provide real-time updates to the front-end.',
    //     },
    //     {
    //       name: 'Test & Deploy',
    //       desc: 'The solution underwent extensive testing before deployment, including usability testing with dyslexic individuals.',
    //     },
    //     {
    //       name: 'Maintenance',
    //       desc: 'We used modern and standardized technologies for the solution to ensure it was easily maintainable.',
    //     },
    //   ],
    // },
    outcome: `This digital evolution, powered by Thorn Creative, seamlessly marries design finesse with modern technology. Brygghallen's virtual presence becomes an intuitive extension, allowing easy management for even non-tech users. The fusion of creative brilliance and technological prowess invites all to partake in Brygghallen's blend of brewing mastery and culinary artistry.`,
    testimonials: {
      desc: `Our collaboration with Vilje Tech was transformative. Their expertise in WordPress development brought Thorn Creative's vision to life, enhancing our customer experience. Brygghallen is now more than a taproom; it's a hub where brewing, culinary delights, and digital craftsmanship thrive. We're thankful for Vilje Tech's dedication and innovation.`,
      name: `Michelle`,
      companyName: 'Key Account, Thorn Creative',
      image: testimonialSampleProfile,
    },
    technologiesUSed: ['1. Figma', '2. Wordpress'],
  },

  {
    id: 10,
    title: 'Prizbee',
    headerText: 'Prizbee',
    bannerImage: prizbee2Og,
    year: '2022',
    flagImg: '',
    servicesOffered: 'App development (Frontend).',
    overview: `Embark on a transformative journey with Prizbee, the brainchild of knowledge-hungry gamers seeking to bridge the gap between the worlds of gaming and learning. Recognizing that gaming culture is an integral part of modern childhood, Prizbee offers a unique solution for parents to ignite their children's passion for learning through the joy of play.`,
    webLink: '',
    section2: {
      image: prizbeeHifi1,
      headText: `Nurturing minds, elevating play: Prizbee's learning-powered gaming odyssey.`,
      desc: `Prizbee's inception was fueled by the curiosity of avid gamers who pondered the intersection of gaming and education. They understood the undeniable allure of gaming in the lives of children and envisioned a way to channel this enthusiasm into meaningful learning experiences. Prizbee acknowledges that while kids will play, there's an opportunity to cultivate their growth through a blend of engaging gameplay and educational enrichment.`,
      desc2: `With the help of React Native, Prizbee's frontend app was meticulously crafted, mirroring the dedication they pour into their mission. Prizbee empowers parents to inspire their children by demonstrating that learning leads to enhanced gameplay. The platform forges collaborations with game developers and entices young players with exclusive rewards, creating an enticing balance between screen time and educational exploration.`,
    },
    // reasearchResult: {
    //   images: [imvi3],
    //   headText:
    //     'To gather insights for our redesign, we conducted a quantitative research with the existing imvi mobile app: ',
    //   results: [
    //     {
    //       percent: '90%',
    //       desc: 'of users felt the app is complex & lacks proper usage instructions',
    //     },
    //     {
    //       percent: '72%',
    //       desc: 'of users voted the previous app as “Poor” in terms of end-user experience',
    //     },
    //     {
    //       percent: '65%',
    //       desc: 'of existing user group felt there is no incentive to keep training on regular basis',
    //     },
    //   ],
    // },
    // wireFrames: {
    //   headText: 'Developing the website:',
    //   images: [bryggHifi2],
    //   desc: ``,
    // },
    // imagesSection: {
    //   headText: 'Designing a better mobile app experience for IMVI:',
    //   images: [imviHifi1, imviHifi2, imviHifi3],
    //   desc: 'We made sure the final product is informational and requires minimal learning curve, both for guardians and training-users. We also came up with a step-wise questions for vergence test (previously designed as list) to eliminate the possibility of users skipping certain questions due to cluttered appearance of the list-view. ',
    //   desc2:
    //     'We felt notifications alone won’t give enough incentive for users to keep training on a regular basis, so we designed “Goals” structure where users can pick a weekly goal and can monitor their progress.',
    // },
    // developingProcess: {
    //   head: 'Developing the app:',
    //   steps: [
    //     {
    //       name: 'Frontend',
    //       desc: 'We designed the user interface to be simple and intuitive, with clear instructions and feedback.',
    //     },
    //     {
    //       name: 'Backend',
    //       desc: 'Firebase was used to store user data and provide real-time updates to the front-end.',
    //     },
    //     {
    //       name: 'Test & Deploy',
    //       desc: 'The solution underwent extensive testing before deployment, including usability testing with dyslexic individuals.',
    //     },
    //     {
    //       name: 'Maintenance',
    //       desc: 'We used modern and standardized technologies for the solution to ensure it was easily maintainable.',
    //     },
    //   ],
    // },
    outcome: `The outcome is truly remarkable. Prizbee offers parents a tool to ignite a passion for learning within their children while embracing the allure of gaming. Through the frontend app's user-friendly interface, Prizbee opens a portal to a world where knowledge and play intertwine harmoniously.`,
    testimonials: {
      desc: `Working with Vilje Tech has been a positive experience in all aspects. As app-development is a constantly changing process with updates, changes and limitations, Bharat and his team proved to always be adaptable, creative and easy to discuss changes with. Be it additional functions or removal of unnecessary functions for streamlining, all was quickly and correctly implemented by the great team at Vilje to create a great end-product. We would be more than happy to continue working with them in the future.`,
      name: `Christoffer Karlöf,`,
      companyName: 'Co-Founder, Prizbee',
      image: testimonialSampleProfile,
    },
    technologiesUSed: ['1. Figma', '2. React Native'],
  },

  {
    id: 11,
    title: 'QGames',
    headerText: 'Q Games',
    bannerImage: qGamesOg,
    year: '2023',
    flagImg: '',
    servicesOffered: 'Concept to execution of the Web app.',
    overview:
      'Q Games, the innovative app, is designed to make the most of the time people spend waiting in lines. This brilliant solution offers an array of engaging games that turn mundane moments of waiting into exciting opportunities for entertainment. By seamlessly blending technology and amusement, Q Games is redefining the waiting experience, transforming it into an enjoyable part of everyday life.',
    webLink: 'https://q.viljetech.com/',
    section2: {
      image: qGamesHifi1,
      headText: `Turning tedious wait-times into fun & rewarding experiences.`,
      desc: `The inspiration behind Q Games emerged from the universal frustration of waiting in lines. Recognizing that this ubiquitous experience could be turned into something enjoyable, Q Games was conceived. With a vision to enhance the daily lives of individuals, this app was designed to be accessible and easy to use, delivering the joy of gaming to everyone, everywhere.`,
      desc2: `Q Games offers a unique solution for those moments when life forces you to queue up. Through an impressive selection of games, this app ensures that waiting is no longer a tedious experience. Whether it's waiting in line at the grocery store or for your morning coffee, Q Games has something fun for everyone. It merges cutting-edge technology with captivating gameplay, turning idle moments into a thrilling experiences.`,
    },
    // reasearchResult: {
    //   images: [imvi3],
    //   headText:
    //     'To gather insights for our redesign, we conducted a quantitative research with the existing imvi mobile app: ',
    //   results: [
    //     {
    //       percent: '90%',
    //       desc: 'of users felt the app is complex & lacks proper usage instructions',
    //     },
    //     {
    //       percent: '72%',
    //       desc: 'of users voted the previous app as “Poor” in terms of end-user experience',
    //     },
    //     {
    //       percent: '65%',
    //       desc: 'of existing user group felt there is no incentive to keep training on regular basis',
    //     },
    //   ],
    // },
    // wireFrames: {
    //   headText: 'Developing the website:',
    //   images: [bryggHifi2],
    //   desc: ``,
    // },
    imagesSection: {
      headText: `Designing and Developing the MVP version of the website:`,
      images: [qGamesHifi2, qGamesHifi3],
      desc: '',
      desc2: '',
      outcome: `The result of Q Games' innovation is a world where waiting no longer feels like a waste of time. The app seamlessly blends technology and amusement, making waiting an exciting part of the day. By transforming the waiting experience into an opportunity for enjoyment, Games has not only alleviated the frustration of queues but has also brought smiles to the faces of countless individuals, proving that waiting can be fun and fulfilling.`,
      technologiesUSed: ['1. Figma', '2. MongoDB', '3. AWS', '4. React JS'],
    },
    // developingProcess: {
    //   head: 'Developing the app:',
    //   steps: [
    //     {
    //       name: 'Frontend',
    //       desc: 'We designed the user interface to be simple and intuitive, with clear instructions and feedback.',
    //     },
    //     {
    //       name: 'Backend',
    //       desc: 'Firebase was used to store user data and provide real-time updates to the front-end.',
    //     },
    //     {
    //       name: 'Test & Deploy',
    //       desc: 'The solution underwent extensive testing before deployment, including usability testing with dyslexic individuals.',
    //     },
    //     {
    //       name: 'Maintenance',
    //       desc: 'We used modern and standardized technologies for the solution to ensure it was easily maintainable.',
    //     },
    //   ],
    // },

    // outcome: `The outcome is truly remarkable. Prizbee offers parents a tool to ignite a passion for learning within their children while embracing the allure of gaming. Through the frontend app's user-friendly interface, Prizbee opens a portal to a world where knowledge and play intertwine harmoniously.`,
    // testimonials: {
    //   desc: `Working with Vilje Tech has been a positive experience in all aspects. As app-development is a constantly changing process with updates, changes and limitations, Bharat and his team proved to always be adaptable, creative and easy to discuss changes with. Be it additional functions or removal of unnecessary functions for streamlining, all was quickly and correctly implemented by the great team at Vilje to create a great end-product. We would be more than happy to continue working with them in the future.`,
    //   name: `Christoffer Karlöf,`,
    //   companyName: 'Co-Founder, Prizbee',
    //   image: testimonialSampleProfile,
    // },
    // technologiesUSed: ['1. Figma', '2. React Native'],
  },

  {
    id: 12,
    title: 'WellnessStudio',
    headerText: 'Wellness Studio',
    bannerImage: wellnessOg,
    year: '2023',
    flagImg: '',
    servicesOffered: 'New feature design for the existing Gym App.',
    overview: `The Wellness Studio Gym App, a beacon of health and fitness, sought to enhance the experience of its members by introducing a streamlined complaint-raising system. This invaluable feature was designed to ensure that members' feedback and concerns are heard and addressed promptly, contributing to a seamless and empowering fitness journey.`,
    webLink: '',
    section2: {
      image: wellnessHifi1,
      headText: `Creating a nurturing and motivating gym environment with user feedback.`,
      desc: `Recognizing the importance of member feedback in creating a nurturing and motivating gym environment, Wellness Studio Gym embarked on the journey to improve their digital ecosystem. The idea was to foster a stronger connection between members and the gym, ensuring that every voice was heard and every concern addressed.`,
      desc2: `The Wellness Studio Gym App, in collaboration with our team, introduced a robust and user-friendly complaint-raising system. This thoughtful addition allows gym members to voice their concerns or provide feedback effortlessly. The system streamlines the process of addressing issues, creating a more responsive and member-centric environment. It empowers gym-goers to actively contribute to the enhancement of their fitness experience.`,
    },
    // reasearchResult: {
    //   images: [imvi3],
    //   headText:
    //     'To gather insights for our redesign, we conducted a quantitative research with the existing imvi mobile app: ',
    //   results: [
    //     {
    //       percent: '90%',
    //       desc: 'of users felt the app is complex & lacks proper usage instructions',
    //     },
    //     {
    //       percent: '72%',
    //       desc: 'of users voted the previous app as “Poor” in terms of end-user experience',
    //     },
    //     {
    //       percent: '65%',
    //       desc: 'of existing user group felt there is no incentive to keep training on regular basis',
    //     },
    //   ],
    // },
    // wireFrames: {
    //   headText: 'Developing the website:',
    //   images: [bryggHifi2],
    //   desc: ``,
    // },
    imagesSection: {
      headText: `Designing a user-friendly complaint-raising system:`,
      images: [wellnessHifi2],
      desc: '',
      desc2: '',
      outcome: `The implementation of the complaint-raising system in the Wellness Studio Gym App has resulted in a more engaged and satisfied membership base. Members now have a direct channel to express their concerns, provide feedback, and actively contribute to the continuous improvement of their fitness journey. This innovative approach has transformed Wellness Studio Gym into a dynamic and responsive fitness community, where members are not just participants but active contributors to their own wellness.`,
      technologiesUSed: ['1. Figma'],
    },
    // developingProcess: {
    //   head: 'Developing the app:',
    //   steps: [
    //     {
    //       name: 'Frontend',
    //       desc: 'We designed the user interface to be simple and intuitive, with clear instructions and feedback.',
    //     },
    //     {
    //       name: 'Backend',
    //       desc: 'Firebase was used to store user data and provide real-time updates to the front-end.',
    //     },
    //     {
    //       name: 'Test & Deploy',
    //       desc: 'The solution underwent extensive testing before deployment, including usability testing with dyslexic individuals.',
    //     },
    //     {
    //       name: 'Maintenance',
    //       desc: 'We used modern and standardized technologies for the solution to ensure it was easily maintainable.',
    //     },
    //   ],
    // },

    // outcome: `The outcome is truly remarkable. Prizbee offers parents a tool to ignite a passion for learning within their children while embracing the allure of gaming. Through the frontend app's user-friendly interface, Prizbee opens a portal to a world where knowledge and play intertwine harmoniously.`,
    // testimonials: {
    //   desc: `Working with Vilje Tech has been a positive experience in all aspects. As app-development is a constantly changing process with updates, changes and limitations, Bharat and his team proved to always be adaptable, creative and easy to discuss changes with. Be it additional functions or removal of unnecessary functions for streamlining, all was quickly and correctly implemented by the great team at Vilje to create a great end-product. We would be more than happy to continue working with them in the future.`,
    //   name: `Christoffer Karlöf,`,
    //   companyName: 'Co-Founder, Prizbee',
    //   image: testimonialSampleProfile,
    // },
    // technologiesUSed: ['1. Figma', '2. React Native'],
  },

  {
    id: 13,
    title: 'Timesheets',
    headerText: 'Timesheets',
    bannerImage: timeSheetsOg,
    year: '2021',
    flagImg: '',
    servicesOffered: 'Web Development.',
    overview: `Timesheets, a transformative tool, has been designed to empower transportation companies by offering a comprehensive solution for tracking drivers' time. This innovative system not only streamlines the scheduling and payment process but also significantly enhances the efficiency and transparency of operations. Timesheets are reshaping the way transportation companies manage their most valuable asset - their drivers.`,
    webLink: '',
    section2: {
      image: timeSheetsHifi1,
      headText: `Time Mastery for Transportation Excellence: Track, Schedule, and Pay with Precision.`,
      desc: `The idea behind Timesheets was born out of the recognition that transportation companies face unique challenges in managing driver schedules and ensuring accurate compensation. Timesheets aimed to bridge this gap by creating a tool that not only addresses these challenges but also leverages technology to enhance operations.`,
      desc2: `Timesheets’ solution is a game-changer for transportation companies. It seamlessly integrates time tracking, scheduling, and hourly pay management into a single, user-friendly platform. With features designed to simplify operations, it ensures that drivers’ time is accurately recorded, schedules are optimized, and payments are precisely calculated. This solution represents a technological advancement that improves efficiency and transparency throughout the entire transportation process.`,
    },
    // reasearchResult: {
    //   images: [imvi3],
    //   headText:
    //     'To gather insights for our redesign, we conducted a quantitative research with the existing imvi mobile app: ',
    //   results: [
    //     {
    //       percent: '90%',
    //       desc: 'of users felt the app is complex & lacks proper usage instructions',
    //     },
    //     {
    //       percent: '72%',
    //       desc: 'of users voted the previous app as “Poor” in terms of end-user experience',
    //     },
    //     {
    //       percent: '65%',
    //       desc: 'of existing user group felt there is no incentive to keep training on regular basis',
    //     },
    //   ],
    // },
    // wireFrames: {
    //   headText: 'Developing the website:',
    //   images: [bryggHifi2],
    //   desc: ``,
    // },
    imagesSection: {
      headText: `Developing of the website and the Outcome:`,
      images: [timeSheetsHifi2],
      desc: '',
      desc2: '',
      outcome: `The implementation of Timesheets has led to a paradigm shift in the way transportation companies operate. It has significantly improved the management of drivers’ time, ensuring that schedules are optimized, and drivers are fairly compensated. With this innovative tool, transportation companies are experiencing enhanced efficiency and transparency in their operations, ultimately leading to a more harmonious and productive working environment for both drivers and the company.`,
      technologiesUSed: ['1. HTML', '2. PHP'],
    },
    // developingProcess: {
    //   head: 'Developing the app:',
    //   steps: [
    //     {
    //       name: 'Frontend',
    //       desc: 'We designed the user interface to be simple and intuitive, with clear instructions and feedback.',
    //     },
    //     {
    //       name: 'Backend',
    //       desc: 'Firebase was used to store user data and provide real-time updates to the front-end.',
    //     },
    //     {
    //       name: 'Test & Deploy',
    //       desc: 'The solution underwent extensive testing before deployment, including usability testing with dyslexic individuals.',
    //     },
    //     {
    //       name: 'Maintenance',
    //       desc: 'We used modern and standardized technologies for the solution to ensure it was easily maintainable.',
    //     },
    //   ],
    // },

    // outcome: `The outcome is truly remarkable. Prizbee offers parents a tool to ignite a passion for learning within their children while embracing the allure of gaming. Through the frontend app's user-friendly interface, Prizbee opens a portal to a world where knowledge and play intertwine harmoniously.`,
    // testimonials: {
    //   desc: `Working with Vilje Tech has been a positive experience in all aspects. As app-development is a constantly changing process with updates, changes and limitations, Bharat and his team proved to always be adaptable, creative and easy to discuss changes with. Be it additional functions or removal of unnecessary functions for streamlining, all was quickly and correctly implemented by the great team at Vilje to create a great end-product. We would be more than happy to continue working with them in the future.`,
    //   name: `Christoffer Karlöf,`,
    //   companyName: 'Co-Founder, Prizbee',
    //   image: testimonialSampleProfile,
    // },
    // technologiesUSed: ['1. Figma', '2. React Native'],
  },

  {
    id: 14,
    title: 'SOOP',
    headerText: 'SOOP',
    bannerImage: soopOg,
    year: '2023',
    flagImg: '',
    servicesOffered: 'Concept to execution of the SaaS platform.',
    overview: `Soop, a cutting-edge SAAS platform catering to second-hand stores in Copenhagen, embarked on a journey to modernize and streamline its digital operations. With a mission to empower thrift stores with advanced technology, Soop engaged our services to revamp both its frontend and backend, setting the stage for a digital transformation in Copenhagen's second-hand retail landscape.`,
    webLink: 'https://demo.soopsystem.com/',
    section2: {
      image: soopHifi1,
      headText: `Second-hand stores in Denmark made efficient and accessible.`,
      desc: `In the bustling second-hand retail scene of Copenhagen, Soop recognized the need for a powerful digital platform to connect stores with tech-savvy shoppers. Their vision was to create a unified solution that would benefit both store owners and customers. To achieve this, Soop sought to revamp its digital infrastructure, a decision that led to our collaboration.`,
      desc2: `Our collaboration with Soop marked a significant step in enhancing the efficiency and accessibility of second-hand stores in Copenhagen. We undertook the comprehensive task of redesigning the frontend and optimizing the backend infrastructure. This involved creating an intuitive user interface and enhancing the platform's functionality to simplify store management and enhance the shopping experience for customers.`,
    },
    // reasearchResult: {
    //   images: [imvi3],
    //   headText:
    //     'To gather insights for our redesign, we conducted a quantitative research with the existing imvi mobile app: ',
    //   results: [
    //     {
    //       percent: '90%',
    //       desc: 'of users felt the app is complex & lacks proper usage instructions',
    //     },
    //     {
    //       percent: '72%',
    //       desc: 'of users voted the previous app as “Poor” in terms of end-user experience',
    //     },
    //     {
    //       percent: '65%',
    //       desc: 'of existing user group felt there is no incentive to keep training on regular basis',
    //     },
    //   ],
    // },
    // wireFrames: {
    //   headText: 'Developing the website:',
    //   images: [bryggHifi2],
    //   desc: ``,
    // },
    imagesSection: {
      headText: `Developing SOOP’s digital platform and the Outcome:`,
      images: [soopHifi2],
      desc: '',
      desc2: '',
      outcome: `The partnership between Soop and our team has led to a transformative digital platform. The revamped frontend offers an intuitive and user-friendly interface for store owners and shoppers alike. On the backend, the optimized infrastructure has significantly improved the platform's performance and reliability. Soop's commitment to empowering secondhand stores with advanced technology is now a reality, bringing enhanced efficiency and a seamless shopping experience to Copenhagen's thrift stores.`,
      technologiesUSed: ['1. Figma', '2. MongoDB', '3. AWS', '4. React JS'],
    },
    // developingProcess: {
    //   head: 'Developing the app:',
    //   steps: [
    //     {
    //       name: 'Frontend',
    //       desc: 'We designed the user interface to be simple and intuitive, with clear instructions and feedback.',
    //     },
    //     {
    //       name: 'Backend',
    //       desc: 'Firebase was used to store user data and provide real-time updates to the front-end.',
    //     },
    //     {
    //       name: 'Test & Deploy',
    //       desc: 'The solution underwent extensive testing before deployment, including usability testing with dyslexic individuals.',
    //     },
    //     {
    //       name: 'Maintenance',
    //       desc: 'We used modern and standardized technologies for the solution to ensure it was easily maintainable.',
    //     },
    //   ],
    // },

    // outcome: `The outcome is truly remarkable. Prizbee offers parents a tool to ignite a passion for learning within their children while embracing the allure of gaming. Through the frontend app's user-friendly interface, Prizbee opens a portal to a world where knowledge and play intertwine harmoniously.`,
    // testimonials: {
    //   desc: `Working with Vilje Tech has been a positive experience in all aspects. As app-development is a constantly changing process with updates, changes and limitations, Bharat and his team proved to always be adaptable, creative and easy to discuss changes with. Be it additional functions or removal of unnecessary functions for streamlining, all was quickly and correctly implemented by the great team at Vilje to create a great end-product. We would be more than happy to continue working with them in the future.`,
    //   name: `Christoffer Karlöf,`,
    //   companyName: 'Co-Founder, Prizbee',
    //   image: testimonialSampleProfile,
    // },
    // technologiesUSed: ['1. Figma', '2. React Native'],
  },
];
const insightsData = [
  {
    id: 1,
    author: 'Bharath Panyala',
    position: ' CEO of Vilje',
    image: bp1Og,
    hoverImage: bp1Zb,
    blogalt: 'Two robots having a conversation',
    alt: 'Photograph of Bharath Panyala',
    imagealt: '',
    headerText: `Opinion: The future of design in robotic internet`,
    title: 'The-future-of-design-in-robotic-internet',
    desc: `Over the past week, major players in the AI ​​development world have announced that they will soon integrate AI into workplace tools such as email, Excel sheets, documents, etc. Given the speed at which AI integration is happening in various tools, the world where we ask AI to write an email and read the inbox is not very far off. In such a world, where human interaction with the graphical interface is minimal, design plays an important role. Because robots don't care about the aesthetics, accessibility and experience of a digital product as much as humans do.`,
    seoDesc: `
      An article on how design will be affected by the robotic internet and the use of AI.`,
    Keywords: 'design, robotic internet, AI, UX, UI',
    canonical:
      'https://viljetech.com/insights/The-future-of-design-in-robotic-internet',
    blogContent: [
      {
        id: 1,
        header: 'The robotic internet',
        content: `When the interaction between AI and AI is greater than between AI and human, it is called the robotic internet. Or at least that's what I call it, I don't know if it's even an established term. For example: as a sender, I ask the AI ​​to write an email and send it to a specific person on a specific topic. The AI ​​does what it was created to do, the receiver receives the message and asks the AI ​​to read the message out loud. The only human interaction that occurs in this exchange is between the speaker and the recipient when the message is read to them. Even this interaction is far from what many consider to be an interaction. If this "automated" version of the internet ever becomes a reality, it will be interesting to see what role design will play and how it will evolve in this changing environment.`,
      },
      {
        id: 2,
        header: 'The importance of design in robotic internet',
        content: `As a design agency with a focus on UX and UI, it can sometimes be difficult to keep up with the big and fast changes that follow trends and updates online. Especially when a design can take anywhere from a week to several months to complete, depending on complexity. Within that time, a tremendous amount may have developed in the digital arena. But there are certain pillars that are constant and will probably endure. These pillars are the designers' lawbook and something we learn by heart and used in almost everything we design. But if the basis of human interaction changes, these pillars can also fall, as their importance is no longer as important.`,
      },
      {
        id: 3,
        header: 'The benefits of AI in the design process',
        content: `But all is not lost. The robotic internet can have its good sides too. It can have a significant impact on how we design digital products and services. AI can be used to automate the design process or generate design options based on user feedback and data analysis. This, in turn, can affect how designers choose to format their designs and really the entire design process.`,
      },
      {
        id: 4,
        header: 'What happens to creativity?',
        content: `It is important to recognize that AI technology, despite its benefits and effectiveness, has limitations when it comes to creativity. While AI can generate answers based on patterns and trends, it can't think outside the box the way humans can. This is where creativity comes into play. Creativity means thinking outside the box, taking risks, experimenting and creating something that has not been created before. It is these qualities that can help create innovative solutions and concepts that can drive society forward.`,
        content1: `As we begin to rely on AI to create content, there is a risk that we may lose our creativity. By relying on AI to produce standardized answers, we can become comfortable and over-rely on the technology. This can limit our ability to think outside the box and find new solutions to problems. It is important to remember that AI cannot replace the creativity and innovation that humans can contribute.`,
      },
      {
        id: 5,
        header: 'The design process in a world with AI',
        content: `That said, the use of AI does not mean we should abandon creative thinking altogether. Instead, we can use AI as a complementary tool to support our creative process. By using AI to collect data and identify trends, we can use this information to develop new and exciting ideas. It is also possible to develop AI systems designed to promote creativity, rather than replace it.`,
        content1: `In a world where AI is becoming more and more integrated into our everyday work tools, it is important that we think about how it will affect our interaction with technology and its implications for the design process. The robotic internet may usher in a new era of automation, but at the same time it may also affect creativity and the human element of design. It's exciting to see how AI can improve and streamline the design process, but it's also important that we continue to develop our ability to create innovative and aesthetically pleasing digital products that people want to use.`,
      },
    ],
  },
  {
    id: 2,
    image: bp2Og,
    hoverImage: bp2Zb,
    blogalt: 'Robot sitting on a bench with a computer',
    alt: 'Photograph of Bharath Panyala',
    author: 'Bharath Panyala',
    position: ' CEO of Vilje',
    imagealt: 'Robot-crawling-internet',
    headerText: 'Improve your website’s SEO using ChatGPT',
    title: 'Improve-your-website’s-SEO-using-ChatGPT',
    desc: `Search Engine Optimization (SEO) is an important part of web design and web development in today's digital era. With millions of websites vying for users' attention, it can be difficult and time-consuming to attract visitors to your website without optimizing its content. Creating and maintaining website content can be a laborious process that requires extensive research and analysis, especially when it comes to keyword research and content scouting. The challenge can be even more demanding for companies that have limited budgets, such as startups. Fortunately, there is a solution to this dilemma, and it's TOTALLY free!`,
    seoDesc: `Learn how ChatGPT, the AI-powered platform, can help you improve your website's SEO, generate high-quality content, and optimize your content for search engines.`,
    Keywords: 'design, robotic internet, AI, UX, UI',
    canonical:
      'https://viljetech.com/insights/Improve-your-website’s-SEO-using-ChatGPT',

    blogContent: [
      {
        id: 1,
        header: 'Intro to SEO and ChatGPT',
        content: `The AI-powered ChatGPT has taken the digital world by storm, offering a solution that saves time and improves website SEO. In this article, we will explore how ChatGPT can help improve your website's SEO and drive more traffic to your website. By using ChatGPT, businesses can optimize their content effectively, improve their search engine rankings, and establish their online presence. The primary goal of SEO is to improve a website's ranking in search engine results pages (SERPs), which leads to increased traffic, higher brand awareness and ultimately more sales. With ChatGPT, businesses can achieve these goals without having to pay high costs or have extensive technical knowledge about SEO or content creation.`,
      },
      {
        id: 2,
        header: 'Generation of quality content',
        content: `In addition to optimizing your website's existing content, ChatGPT can help you create high-quality and relevant content that meets the needs of your target audience. Producing engaging and informative content is critical to attracting and retaining visitors to your website. With ChatGPT, businesses can generate blog posts, product descriptions or page content that is both SEO-friendly and user-focused.`,
      },
      {
        id: 3,
        header: 'Addendum AIPRM',
        content: `The AI-powered platform can help you identify the right keywords, optimize your content for search engines and improve your website's ranking in SERPs. Additionally, ChatGPT offers a Chrome extension, AIPRM for ChatGPT, that can further simplify content creation. This plugin has features to generate SEO optimized articles, create a list of keywords relevant to your area and generate backlinks to your site. By using ChatGPT and AIPRM, companies can streamline their content creation process, saving valuable time and resources while producing high-quality content that is optimized for search engines and engaging for users.`,
        link: {
          text: 'Want to know more about the ChatGPT extension AIPRM?',
          linkText: ' Check out our article on AIPRM!',
          linkUrl:
            '/insights/AIPRM:The-Revolutionary-SEO-extension-for-ChatGPT',
        },
      },
      {
        id: 4,
        header: 'Keyword optimization',
        content: `Optimizing your keywords is an essential part of a successful SEO strategy. To effectively optimize your content, you need to have a basic understanding of how people and search engines search the internet, what words they use and what questions they want answered. By using the right words and phrases, you increase the chance that your content will be an exact match for a search query, which can improve your site's ranking in search engine results.`,
        content1: `This is where ChatGPT comes in. ChatGPT can help you identify the best keywords for your website and optimize your content for those keywords. This includes both on-page optimization, such as using keywords in your content and meta tags, as well as off-page optimization, such as building high-quality backlinks to your website. Once you have a suitable list of potential keywords that fit your area of ​​interest, you can implement them into your existing content or use them to create new optimized content, either by creating it yourself, hiring someone to create it for you, or using ChatGPT and the AIPRM extension.`,
      },
      {
        id: 5,
        header: 'ChatGPT and website structure',
        content: `When it comes to optimizing your website for search engines, website structure and navigation are critical factors to consider. A well-organized website structure not only makes it easier for users to find the information they need, but it also helps search engines understand the content of your website. This is where ChatGPT's AI solution comes into play.`,
        content1: ` With ChatGPT, you can create a website structure that is both user-friendly and optimized for search engines. This means categorizing your content into relevant categories, using clear and descriptive URLs, and providing easy-to-navigate links. By doing this, you not only make it easier for users to find what they're looking for, but also ensure that search engines can crawl and index your site more efficiently.`,
      },
      {
        id: 6,
        header: 'The risk of relying solely on AI for SEO optimizations',
        content: `But all is not gold and green forests. AI is becoming an increasingly large part of our digital reality, and we as users are beginning to benefit from the accessibility and simplicity of generated content. However, there are also risks in relying solely on ChatGPT. SEO is a complex art form that requires knowledge of various areas within web development, communication and analysis. Relying solely on an AI can therefore be problematic, and you can forget about important optimizations that can give your website the real success on the SERPs. Nevertheless, ChatGPT and other AI solutions are excellent tools to get inspiration or a good foundation that you or SEO experts can then build on, or vice versa, where AI becomes an excellent tool that can improve an already expertly built SEO strategy. If you need help evaluating your page's SEO or want help optimizing your on-page or off-page SEO, Vilja Tech is always on hand to help you. We can also discuss more about AI and future technologies.`,
      },
    ],
  },
  {
    id: 3,
    image: bp3Og,
    hoverImage: bp3Zb,
    blogalt: 'SEO Extension for ChatGPT',
    alt: 'Photograph of Bharath Panyala',
    author: 'Bharath Panyala',
    position: ' CEO of Vilje',
    imagealt: 'AIPRM extension',
    headerText: 'AIPRM: The Revolutionary SEO Extension for ChatGPT',
    title: 'AIPRM:The-Revolutionary-SEO-extension-for-ChatGPT',
    desc: "As businesses move towards digital marketing, search engine optimization (SEO) becomes a critical element in ensuring online success. With the vast amount of content available online, it's essential to stand out from the crowd and optimise your content for search engines. This is where AIPRM comes in - the revolutionary SEO extension for ChatGPT that provides you with SEO prompts from other users.",
    desc1:
      'If you want to learn more about Chatgpt and its impact on generating content then look into this article.',
    desc2: "But let's get back on track, the great AIPRM, what is it? ",
    seoDesc:
      'Optimise your content for search engines with AIPRM - the revolutionary SEO extension for ChatGPT that provides user-generated prompts. Improve rankings and increase online visibility. Learn more here!',
    Keywords: 'EO, ChatGPT, AIPRM, Guide',
    canonical:
      'https://viljetech.com/blogposts/AIPRM:The-Revolutionary-SEO-extension-for-ChatGPT',

    blogContent: [
      {
        id: 1,
        header: 'What is AIRPRM?',
        content:
          "AIPRM is an extension for ChatGPT that utilises artificial intelligence to provide SEO prompts to users. It's a simple yet powerful tool that helps businesses optimise their content for search engines and gain better online visibility. The extension works by analysing other users' content and providing prompts that help you optimise your content for specific keywords and phrases. The extension comes with a lot - over 2100 prompts - that you can choose from when creating your content. Everything from creating whole articles to keyword lists that can help you create your own optimised content.",
      },
      {
        id: 2,
        header: 'Guide to AIPRM',
        content:
          "Our advice for using AIPRM in your content creation is to see it more as a guide than an easy fix for creating your content quota for the day. The AI is still learning, and new updates are on the way, as is a new law on artificial intelligence that could hinder the use and sharing of AI-generated content. Therefore, we encourage using AIPRM to get out of your writer's block and get inspired by the introductions or conclusions that the extension provides or by using the keyword list as a map for your journey of exploring more possible keywords, both long-tailed and short-tailed.",
        content1:
          'Another issue that could emerge from the use of AI-generated content is the human touch. There are already a sea of detectors that can determine whether the content is man-made or generated, and if you have a trained eye, it is pretty obvious. ',
      },
      {
        id: 3,
        header: 'Benefits of AIPRM',
        content:
          'But after looking on some of the restrictions, here are the benefits of using AIPRM:',
        list: [
          {
            li: '1. Improved search engine rankings: AIPRM provides you with SEO prompts that help you optimise your content for search engines. This, in turn, improves your search engine rankings, making it easier for people to find your content online.',
          },
          {
            li: '2. Increased online visibility: With improved search engine rankings comes increased online visibility. AIPRM helps businesses stand out from the crowd and get noticed online.',
          },
          {
            li: '3. Saves time and effort: AIPRM automates the SEO process, saving businesses time and effort. It eliminates the need to manually research and optimise content for search engines.',
          },
          {
            li: '4. User-generated prompts: AIPRM utilises user-generated prompts, making it a collaborative tool. It allows users to share their SEO knowledge and expertise with others, making the optimization process more efficient.',
          },
        ],
        content2:
          'Are you convinced of using the power of AIPRM? Check out this video that guides you through the process of setting it up, it is quick and easy! ',
        youtubelink: 'https://youtu.be/Xrm3ztmxi28 ',
      },
    ],
  },
  {
    id: 4,
    image: bp4Og,
    hoverImage: bp4Zb,
    blogalt: 'Image of two hands reaching out to each other',
    alt: 'Photograph of Bharath Panyala',
    author: 'Bharath Panyala ',
    position: ' CEO of Vilje',
    imagealt: 'AIPRM extension',
    headerText:
      'Building Long-Lasting Relationships: The Key to Making Authentic Sales',
    title:
      'Building-Long-Lasting-Relationships:The-Key-to-Making-Authentic-Sales',
    desc: `As the CEO of Vilje, I strongly believe in building long-lasting relationships with our clients. Sales are not just about closing a deal; they are about fostering connections, understanding our clients' needs, and creating value that lasts beyond a transaction. As the great Zig Ziglar once said, "If people like you, they'll listen to you, but if they trust you, they'll do business with you."`,
    desc1:
      'Here are some truths that we live by to make authentic sales and build long-lasting relationships with our clients:',
    seoDesc:
      'Make authentic sales and build long-lasting relationships with clients. Read about the Vilje way here. ',
    Keywords:
      'authentic sales, long-lasting relationships, understanding client needs, offering value, honesty and transparency, delivering on promises, maintaining communication, client satisfaction, business relationships.',
    canonical:
      'https://viljetech.com/blogposts/Building-Long-Lasting-Relationships:The-Key-to-Making-Authentic-Sales',

    blogContent: [
      {
        id: 1,
        header: 'Understanding Client Needs.',
        content:
          "The first step in building an authentic sales relationship is to understand your clients' needs. By listening carefully to what they want, asking questions, and getting to know their business goals, you can create customised solutions that fit their needs perfectly.",
        content1:
          'By understanding the needs of your client on a micro and macro level, you can get a better grasp of the whole project and the organisation. On a micro level, you want to understand the goals and objectives of the project and your client. On the other hand, on a macro level, you want to be able to get insight into the organisation and the culture that exists within it.',
      },
      {
        id: 2,
        header: 'Find Value in Work.',
        content:
          "As a company, it's important to have a driving force that goes beyond financial gain. One such force could be a sense of ownership and pride in the products created for clients. ",
        content1:
          "Another could be experimentation with techniques and solutions during project development, as long as the client is involved. By collaborating with clients to create something greater than just the end product, and by seeing value in this process, your team will find greater fulfillment and passion in their work. This sense of ownership and collaboration can lead to the creation of exceptional solutions that exceed client expectations, which, in turn, strengthens your company's reputation and success.",
      },
      {
        id: 3,
        header: 'Offering Value.',
        content:
          'Providing value beyond products and services is important. This can be done by offering free consultations, sharing industry insights and knowledge, and providing support even after the sale is made. By offering value and demonstrating care for clients, trust can be built, and long-term relationships can be established.',

        content1:
          "For clients, being engaged in their project's journey and seeing the full perspective will give them a sense of empathy and understanding. This is also crucial in creating long-lasting and thriving relationships. Another key point is that by going the extra mile and delivering a little extra, clients will see added value to the service provided, not just in terms of value for money but for their time and effort as well.",
      },
      {
        id: 4,
        header: 'Honesty and Transparency.',
        content:
          'Honesty and transparency are fundamental values that need to be upheld when dealing with clients. As a company you need to believe that building a long-lasting relationship is more important than a short-term sale. By being transparent about services, pricing, and delivery, trust and credibility are established with clients.',

        content1:
          'Using emotional intelligence when communicating with clients can help tailor your approach to specific situations and enhance your communication and engagement. Honesty is especially crucial since it affects culture, motivation, and even reputation.',
      },
      {
        id: 5,
        header: 'Delivering on Promises.',
        content:
          "With the previous points in mind, it's important to acknowledge that they all contribute to the end product. Delivering a product can be more challenging than expected, with several factors to consider. Firstly, sticking to the project deadline is crucial. Secondly, the delivery itself must meet certain standards. Finally, client feedback is essential for ensuring their satisfaction.",

        content1:
          'Delivering on promises and ensuring client satisfaction remains a top priority. Exceeding their expectations and making sure they feel valued and heard is key.',
        content2:
          'One way to guarantee client satisfaction with the end product is to request feedback in a neutral and friendly manner. We welcome opportunities to improve and believe that feedback is a crucial part of that process.',
      },
      {
        id: 6,
        header: 'Final thoughts',
        content:
          'So, how do we build strong, long-lasting relationships with our clients? By making authentic sales. By understanding client needs, offering value, being honest and transparent, delivering on promises, and maintaining communication, we create value that lasts beyond a transaction. Being your true authentic self and listening to your clients will get you a long way in the business world. ',

        content1: `As Theodore Roosevelt once said, "People don't care how much you know until they know how much you care." We strive to show our clients that we care and build relationships that stand the test of time.`,
      },
    ],
  },
  {
    id: 5,
    image: bp5Og,
    hoverImage: bp5Zb,
    blogalt: 'A Poster of Abstract representation of UX',
    alt: 'Photograph of Bharath Panyala',
    author: 'Bharath Panyala',
    position: 'Communication Manager, Vilje',
    imagealt: 'Abstract representation of User Experience',
    headerText:
      '3 Simple Changes to Improve the User Experience on Your Website',
    title: '3-Simple-Changes-to-Improve-the-User-Experience-on-Your-Website',
    desc: `The user experience (UX) of your website can make or break your online success. A great UX can lead to increased engagement, higher conversion rates, and better brand recognition, while a poor UX can result in high bounce rates, low customer satisfaction, and reduced sales. Learn how to improve the user experience in three easy steps.`,
    seoDesc:
      "Learn about three easy fixes to improve your website's User Experience!",
    Keywords:
      'User Experience, Navigation, Page Speed, Mobile-Friendly, User Interface ',
    canonical:
      'https://viljetech.com/blogposts/3-Simple-Changes-to-Improve-the-User-Experience-on-Your-Website',
    blogContent: [
      {
        id: 1,
        header: 'What is User Experience? ',
        content:
          "User experience is a crucial aspect of any product, application, or service. It refers to the overall feeling that a user experiences while using it. As a product owner, it's essential to provide a top-notch user experience to ensure that your content is of high quality and that users find it easy to navigate and use.",
      },
      {
        id: 2,
        header: 'Defining User Experience and User Interface',
        content:
          "When discussing user experience, it's often linked to user interface (UI). Although the two terms may seem similar, they are quite different. User interface pertains to the visual aspects of the product, such as the design, layout, and appearance. It includes elements such as typography, colors, style and branding, spacing, boldness and strengths, number of things, icons, and images. It's how things look and feel while you're using them, including the space between them, the amount of information on each screen, and the interactions needed to get things done.",
        content1:
          "On the other hand, user experience (UX) focuses more on the entire experience, including the product's features, functionality, and layout, and how they are used and felt. It considers factors such as the user's location, motivations, feelings, and interactivity. It can be seen as the foundation for the design, similar to a sketch before adding color and graphic elements. Although the line between user experience and user interface can be blurry, their tasks can interfere and overlap.",
      },
      {
        id: 3,
        header: 'How to improve your websites UX:',
        subContent: [
          {
            id: 1,
            header: '1. Simplify Navigation',
            desc: 'To enhance the user experience on your website, the initial step is to simplify navigation. This means providing users with an easy way to locate what they are seeking and reach their intended destination on your site. One approach to simplify navigation is by using clear and intuitive headings, and by organizing your content into categories and subcategories, particularly if your site has many pages. When developing your navigation, keep in mind three main principles - labeling, predictability, and hierarchy.',
            subDesc: [
              {
                header: 'Labeling',
                desc: `Labeling is about using concise, predictable words that are descriptive and to the point. Avoid using vague phrases such as "What We Do" when you can simply state "Work." Be mindful of providing the right amount of information - neither too much nor too little. Use keywords that your target audience is familiar with, and when a label is unfamiliar, use icons, pictures, and short descriptions to assist the user.`,
              },
              {
                header: 'Predictability',
                desc: `Predictability is essential to improving website usability. Don't surprise your users with unexpected navigation patterns. They are used to a specific set of practices. The standard convention is to place the logo in the top left corner, with 
                a horizontal menu bar situated next to or below it.
                `,
              },
              {
                header: 'Hierarchy',
                desc: `Hierarchy is also an important consideration when designing navigation. Typically, people read from left to right and from top to bottom, but to fully comprehend this concept, you must understand your users and how they interact with your site. Determine the primary user paths and arrange the options accordingly. Place the most important pages and categories towards the top and left of the navigation menu.`,
              },
            ],
          },
          {
            id: 2,
            header: '2. Improve Page Speed',
            desc: "In order to improve the user experience on your website, it's crucial to optimize page speed. When a website loads slowly, it can be frustrating for users and may result in high bounce rates, which means that users click on your page but quickly leave. To speed up your website, there are several improvements you can make, such as optimizing your images, reducing the size of your page files, and using a fast and reliable web hosting provider. By implementing these optimizations, you can ensure that users have a fast and seamless experience on your site, which can increase engagement and ultimately drive more conversions.",
            subDesc: [
              {
                header: 'Optimizing Images',
                desc: 'Optimizing your images means reducing the file size of the images on your website without sacrificing quality. This can be done by compressing images, reducing their dimensions, or using a different file format that is more efficient. By doing this, your website will load faster and provide a better user experience.',
              },
              {
                header: 'Reducing Page File Size',
                desc: 'Reducing the size of your page files involves minimizing the amount of data that needs to be transferred when a user loads your website. This can be achieved by removing unnecessary code, using minified versions of CSS and JavaScript files, and reducing the number of HTTP requests. By doing so, your website will load faster and improve the user experience.',
              },
              {
                header: 'Hosting',
                desc: 'Using a fast and reliable web hosting provider is important because it can greatly affect the speed and availability of your website. A good web hosting provider should have fast servers, a reliable uptime guarantee, and provide good customer support in case of any issues. By choosing a quality web hosting provider, you can ensure that your website is fast and reliable for your users.',
              },
            ],
          },
          {
            id: 3,
            header: '3. Make Your Site Mobile-Friendly',
            desc: `In today's mobile-first world, it's essential to have a mobile-friendly website. It is estimated that  60% of internet traffic comes from mobile devices and when ranking for Google, they 
            rank you according to your mobile friendliness. If that is not evidence enough to put a lot of focus on your  mobile-friendly site. When designing for mobile friendliness you need to consider it is easier to use on smaller screens, loads faster on slower connections, and is more accessible to users on the go. To make your site mobile-friendly, you should use a responsive design that automatically adjusts to the size of the user's device.`,
            subDesc: [
              {
                header: 'Why Mobile Friendly',
                desc: `​​In today's digital landscape, having a mobile-friendly website is a must. With over 60% of internet traffic coming from mobile devices, it's clear that mobile optimization is key to success. In fact, Google's search algorithm takes mobile-friendliness into account when ranking websites, making it even more important to prioritize this aspect of your site. To ensure that your website is mobile-friendly, it's crucial to consider how it appears and functions on smaller screens, as well as its loading speed on slower connections. Your site should also be easily accessible for users on the go. We most often seek answers when we are up and on the go. Your UX design should be optimized for both large computer screens and small mobile screens, with a seamless transition between the two. It's crucial that users understand that they are experiencing the same website, just projected differently. One effective way to achieve this is by implementing a responsive design that automatically adjusts to the user's device, providing a great mobile experience.
              `,
              },
            ],
          },
        ],
      },
      {
        id: 4,
        header: 'Finishing thoughts ',
        content:
          "Improving the way people use your website (UX) is very important for success online. A good UX can make people spend more time on your site, buy more things, and remember your brand. But a bad UX can make people leave quickly, not like your site, and buy less. To make your website's UX better, make it easy to move around by having clear headings, organizing things into groups, and following design rules. Make your site fast by having small image files, using a good web hosting service, and thinking about how it looks on phones. If you do these things, people will like your site more and ultimately stay on it for a longer period of time, which is good both for your Google ranking and for getting sales. If you want to learn more about how YOU can optimize your website's UX, contact us and let’s talk! ",
      },
    ],
  },
  // {
  //   id: 1,
  //   image: news1Og,
  //   hoverImage: news1Og,
  //   blogalt: '',
  //   imagealt: '',
  //   headerText: 'Join our Innovation Competition - 50 hours to greatness ',
  //   title: 'Join-our-Innovation-Competition-50-hours-to-greatness',
  //   desc: 'Vilje is announcing our latest competition where you have a chance to bring your vision to life. We firmly believe in the power of innovation to drive change and improvement, and we want to provide an opportunity for start-ups to take their ideas to the next level.',
  //   seoDesc:
  //     'Participate in Vilje competition for the chance of winning 50 development or design hours and turn your idea into reality.',
  //   Keywords:
  //     'Vilje, competition, vision, innovation, start-ups, development, design',
  //   canonical: '',
  //   blogContent: [
  //     {
  //       id: 1,
  //       content:
  //         "Here's how it works: Enter our competition on LinkedIn or Instagram, where you'll find all the information you need on how to participate and the rules for applying. The winner will receive 50 development or design hours, allowing you to turn your idea into a reality.",
  //     },
  //     {
  //       id: 2,
  //       content:
  //         "Whether you're looking to build a minimum viable product (MVP), enhance your brand portfolio, or create wireframes for an application, our team at Vilje is up for a challenge. We understand the challenges faced by early-stage start-ups and want to be your partner in success.",
  //     },
  //     {
  //       id: 3,
  //       content:
  //         'By participating in our competition, you not only get a chance to win valuable development or design hours but also gain exposure to a wider audience. Our platform will provide a stage for you to showcase your innovative idea, potentially attracting investors, mentors, and collaborators who can further propel your entrepreneurial journey.',
  //     },
  //   ],
  // },
];
export const EnglishStrings = {
  navbarStrings,
  common,
  footerStrings,
  homePage,
  testimonialStrings,
  recentPostStrings,
  aboutStrings,
  projectStrings,
  servicesStrings,
  contactStrings,
  blogStrings,
  cookiesPopUp,
  privacyPoliyStrings,
  projectsCommonData,
  projectsData,
  insightsData,
};
