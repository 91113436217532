import React, { useContext, useEffect, useRef, useState } from 'react';
import styles from './styles.module.css';
import NavBar from '../../components/navbar';
import { albinProfilePic, bharathProfilePic } from '../../resources/images';
import Footer from '../../components/footer';
import Divider from '../../components/divider';
import { CrossMarkIcon } from '../../resources/svg';
// import { Helmet } from 'react-helmet';
import emailjs from '@emailjs/browser';
import { AppDataContext } from '../../providers/appDataProvider';

const Contact = () => {
  const { strings } = useContext(AppDataContext);
  const [showPopUp, setShowPopUp] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [errMsg, setErrMsg] = useState({
    name: '',
    email: '',
    company: '',
    message: '',
    checkbox: '',
  });
  const form = useRef();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, []);
  useEffect(() => {
    if (window.innerWidth > 568) {
      const interval = setTimeout(() => {
        setShowPopUp(false);
      }, 2500);
      return () => {
        clearTimeout(interval);
      };
    }
  }, [showPopUp]);
  const profilesData = [
    {
      image: bharathProfilePic,
      name: strings.contactStrings.profileDataOne,
      designation: strings.contactStrings.RoleOne,
      mail: strings.contactStrings.mailOne,
      phoneNumber: strings.contactStrings.numOne,
    },
    {
      image: albinProfilePic,
      name: strings.contactStrings.profileDataTwo,
      designation: strings.contactStrings.RoleTwo,
      mail: strings.contactStrings.mailTwo,
      phoneNumber: strings.contactStrings.numTwo,
    },
  ];

  const sendEmail = (e) => {
    emailjs
      .sendForm(
        'service_4rzxitg',
        'template_qnzyogg',
        form.current,
        'BCL0VqeQ06EXqocoa'
      )
      .then(
        (result) => {
          e.target.reset();
          setCheckboxChecked(false);
        },
        (error) => {
          console.log('error', error);
        }
      );
  };
  const handleValidation = (e) => {
    e.preventDefault();
    const newErrorMsg = {};
    if (e.target.name.value === '') {
      newErrorMsg.name = 'Please Enter name';
    }
    if (e.target.email.value === '') {
      newErrorMsg.email = 'Please Enter mail';
    }
    if (e.target.company.value === '') {
      newErrorMsg.company = 'Please Enter company';
    }
    if (e.target.message.value === '') {
      newErrorMsg.message = 'Please Enter description';
    }
    if (!checkboxChecked) {
      newErrorMsg.checkbox = 'Please consent the form';
    }
    setErrMsg(newErrorMsg);
    if (Object.keys(newErrorMsg).length === 0 && checkboxChecked) {
      handleSubmit(e);
    }
  };
  const handleSubmit = (e) => {
    sendEmail(e);
    setShowPopUp(true);
  };
  const renderPopUp = () => {
    return (
      <div className={styles.popUpContainerStyles}>
        <div
          className={
            // showPopUp ?
            styles.popUpContentStyles
            // : styles.popUpContentHideStyles
          }
        >
          <div className={styles.popUpContentInnerStyles}>
            <p className={styles.popUpContentTextStyles}>
              {strings.contactStrings.thanksPopMsg}
            </p>
            <p
              className={styles.closeTextStyles}
              onClick={() => setShowPopUp(false)}
            >
              <CrossMarkIcon
                customStyles={styles.crossMarkIconStyles}
                pathStyles={styles.crossMarkIconPathStyles}
              />
              Close
            </p>
          </div>
        </div>
      </div>
    );
  };
  const renderProfilesRightSection = () => {
    return (
      <div className={styles.profilesRightContainerStyles}>
        {profilesData.map((item, index) => (
          <div key={index} className={styles.profileCardStyles}>
            <div className={styles.profileImageStyles}>
              <img
                src={item.image}
                alt="profile pic"
                className={styles.imageWidthStyles}
              />
            </div>
            <div className={styles.profileMiddleContainerStyles}>
              <p className={styles.profileNameTextStyles}>{item.name}</p>
              <p className={styles.designationTextStyles}>{item.designation}</p>
            </div>
            <div className={styles.profileBottomContainerStyles}>
              <p className={styles.mailTextStyles}>{item.mail}</p>
              <p className={styles.phoneNumberTextStyles}>{item.phoneNumber}</p>
            </div>
          </div>
        ))}
      </div>
    );
  };
  const renderContactFormRightSection = () => {
    return (
      <form
        className={styles.contactFormStyles}
        ref={form}
        onSubmit={(e) => handleValidation(e)}
      >
        <div className={styles.contactFormInputsContainerStyles}>
          <input
            type={'text'}
            name="name"
            placeholder={strings.contactStrings.fullName}
            className={styles.inputTextViewStyle}
            onFocus={() => setErrMsg({ ...errMsg, name: '' })}
          />
          {errMsg.name && (
            <p className={styles.errMsgTextStyle}>{errMsg.name}</p>
          )}
          <input
            type={'email'}
            name="email"
            onFocus={() => setErrMsg({ ...errMsg, email: '' })}
            placeholder={strings.contactStrings.emailAddress}
            className={styles.inputTextViewStyle}
          />
          {errMsg.email && (
            <p className={styles.errMsgTextStyle}>{errMsg.email}</p>
          )}
          <input
            type={'text'}
            name="company"
            onFocus={() => setErrMsg({ ...errMsg, company: '' })}
            placeholder={strings.contactStrings.company}
            className={styles.inputTextViewStyle}
          />
          {errMsg.company && (
            <p className={styles.errMsgTextStyle}>{errMsg.company}</p>
          )}
          <textarea
            name="message"
            onFocus={() => setErrMsg({ ...errMsg, message: '' })}
            placeholder={strings.contactStrings.tellUsAbout}
            className={styles.inputTextAreaViewStyle}
          />
          {errMsg.message && (
            <p className={styles.errMsgTextStyle}>{errMsg.message}</p>
          )}
        </div>
        <div className={styles.contactFormBottomStyles}>
          <p className={styles.privacyPolicyTextStyles}>
            <input
              type="checkbox"
              name="checkbox"
              onChange={(e) => {
                setCheckboxChecked(e.target.checked);
                setErrMsg({ ...errMsg, checkbox: '' });
              }}
              className={styles.inputCheckboxViewStyle}
            />
            {strings.contactStrings.readAndAgree}
            <span onClick={() => window.open('/privacypolicy', '_blank')}>
              {strings.contactStrings.privacyPolicy}
            </span>
          </p>
          {errMsg.checkbox && (
            <p className={styles.errMsgTextStyle}>{errMsg.checkbox}</p>
          )}
          <button className={styles.sendMessageTextStyles} type="submit">
            {strings.contactStrings.sendMessage}
          </button>
        </div>
      </form>
    );
  };
  return (
    <div className={styles.contactMainContainerStyles}>
      {/* <Helmet>
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js-eu1.hs-scripts.com/27027103.js"
        ></script>
      </Helmet> */}
      <NavBar />
      <div className={styles.contactContainerStyles}>
        <h2 className={styles.contactHeadingTextStyles}>
          {strings.contactStrings.headerOne}
        </h2>
        <div className={styles.profilesContainerStyles}>
          <p className={styles.idNumberTextStyles}>{`{01}`}</p>
          {renderProfilesRightSection()}
        </div>
        <div className={styles.contactFormContainerStyles}>
          <p className={styles.idNumberTextStyles}>{`{02}`}</p>
          {renderContactFormRightSection()}
        </div>
      </div>
      <Divider customDividerStyles={styles.customDividerStyles} />
      <Footer
        theme="dark"
        footerBottom={true}
        customFooterBottomStyles={styles.footerStyles}
        customFooterMainContainerStyles={styles.footerMainContainerStyles}
      />
      {showPopUp && renderPopUp()}
    </div>
  );
};

export default Contact;
