import React, { useEffect, useState, useContext } from 'react';
import styles from './styles.module.css';
import NavBar from '../../components/navbar';
import Footer from '../../components/footer';
import {
  step1Img,
  step2Img,
  step3Img,
  step4Img,
  step5Img,
  step6Img,
  step7Img,
  step8Img,
  valuesIcon1,
  valuesIcon2,
  valuesIcon3,
  valuesIcon4,
} from '../../resources/images';
import Divider from '../../components/divider';
import Button from '../../components/button';
import { LeftArrow, RightArrow } from '../../resources/svg';
import ServiceCard from '../../components/servicecard';
import { Helmet } from 'react-helmet';
import { AppDataContext } from '../../providers/appDataProvider';
import { string } from 'yup';
const About = () => {
  const { strings } = useContext(AppDataContext);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, []);
  const [stepCount, setStepCount] = useState(1);

  const whatWeDoListData = [
    {
      id: '01',
      name: strings.aboutStrings.design,
      desc: strings.aboutStrings.designDesc,
    },
    {
      id: '02',
      name: strings.aboutStrings.develop,
      desc: strings.aboutStrings.developDesc,
    },
    {
      id: '03',
      name: strings.aboutStrings.deliver,
      desc: strings.aboutStrings.deliverDesc,
    },
  ];
  const teamValuesData = [
    {
      id: '01',
      icon: valuesIcon1,
      name: strings.aboutStrings.teamsHeaderOne,
      desc: strings.aboutStrings.teamsDescpOne,
    },
    {
      id: '02',
      icon: valuesIcon2,
      name: strings.aboutStrings.teamsHeaderTwo,
      desc: strings.aboutStrings.teamsDescpTwo,
    },
    {
      id: '03',
      icon: valuesIcon3,
      name: strings.aboutStrings.teamsHeaderThree,
      desc: strings.aboutStrings.teamsDescpThree,
    },
    {
      id: '04',
      icon: valuesIcon4,
      name: strings.aboutStrings.teamsHeaderFour,
      desc: strings.aboutStrings.teamsDescpFour,
    },
  ];
  const approachStepsData = [
    {
      id: '01',
      name: strings.aboutStrings.onboarding,
      image: step1Img,
      bgColor: '#DADEE3',
      color: '#303E37',
    },
    {
      id: '02',
      name: strings.aboutStrings.research,
      image: step2Img,
      bgColor: '#28292C',
      color: '#DADEE3',
    },
    {
      id: '03',
      name: strings.aboutStrings.testing,
      image: step3Img,
      bgColor: '#303E37',
      color: '#DADEE3',
    },
    {
      id: '04',
      name: strings.aboutStrings.lowFidelity,
      image: step4Img,
      bgColor: '#30313E',
      color: '#DADEE3',
    },
    {
      id: '05',
      name: strings.aboutStrings.highFidelity,
      image: step5Img,
      bgColor: '#DADEE3',
      color: '#303E37',
    },
    {
      id: '06',
      name: strings.aboutStrings.feedback,
      image: step6Img,
      bgColor: '#28292C',
      color: '#DADEE3',
    },
    {
      id: '07',
      name: strings.aboutStrings.iterate,
      image: step7Img,
      bgColor: '#303E37',
      color: '#DADEE3',
    },
    {
      id: '08',
      name: strings.aboutStrings.handoff,
      image: step8Img,
      bgColor: '#30313E',
      color: '#DADEE3',
    },
    {
      id: '09',
      name: '',
      image: '',
      bgColor: '#191A19',
      color: '#191A19',
      border: '#ffffff33',
    },
  ];
  const teamsData = [
    {
      name: strings.aboutStrings.developingHeader,
      members: [
        {
          name: strings.aboutStrings.personOne,
          role: strings.aboutStrings.personRoleOne,
        },
        {
          name: strings.aboutStrings.personOne,
          role: strings.aboutStrings.personRoleOne,
        },
        {
          name: strings.aboutStrings.personOne,
          role: strings.aboutStrings.personRoleOne,
        },
        {
          name: strings.aboutStrings.personOne,
          role: strings.aboutStrings.personRoleOne,
        },
        {
          name: strings.aboutStrings.personOne,
          role: strings.aboutStrings.personRoleOne,
        },
        {
          name: strings.aboutStrings.personOne,
          role: strings.aboutStrings.personRoleOne,
        },
        {
          name: strings.aboutStrings.personOne,
          role: strings.aboutStrings.personRoleOne,
        },
      ],
    },
    {
      name: strings.aboutStrings.designHeader,
      members: [
        {
          name: strings.aboutStrings.designerOne,
          role: strings.aboutStrings.designerTypeOne,
        },
        {
          name: strings.aboutStrings.designerOne,
          role: strings.aboutStrings.designerTypeOne,
        },
        {
          name: strings.aboutStrings.designerOne,
          role: strings.aboutStrings.designerTypeOne,
        },
        {
          name: strings.aboutStrings.designerOne,
          role: strings.aboutStrings.designerTypeOne,
        },
      ],
    },
    {
      name: strings.aboutStrings.marketingHeader,
      members: [
        {
          name: strings.aboutStrings.marketingOne,
          role: strings.aboutStrings.marketingTypeOne,
        },
        {
          name: strings.aboutStrings.marketingOne,
          role: strings.aboutStrings.marketingTypeTwo,
        },
        {
          name: strings.aboutStrings.marketingOne,
          role: strings.aboutStrings.marketingTypeThree,
        },
        {
          name: strings.aboutStrings.marketingOne,
          role: strings.aboutStrings.marketingTypeThree,
        },
      ],
    },
  ];
  const [CardClassName, setCardClassName] = useState();
  useEffect(() => {
    const cardsEles = [...document.querySelectorAll('#cards')];
    const classNamesArr = cardsEles.map((item) => item.className);
    setCardClassName(classNamesArr);
  }, [stepCount]);
  const renderAboutBannerSection = () => {
    return (
      <div className={styles.aboutBannerOuterContainerStyles}>
        <div className={styles.aboutBannerInnerContainerStyles}>
          <h2 className={styles.aboutBannerHeadingStyles}>
            {strings.aboutStrings.header}
          </h2>
          {/* <div className={styles.rightDownArrowImageStyles}>
            <img
              src={rightDownArrow}
              alt="arrow icon"
              className={styles.imageWidthStyles}
            />
          </div> */}
          <p className={styles.aboutBannerDiscTextStyles}>
            {strings.aboutStrings.descp}
          </p>
        </div>
      </div>
    );
  };
  const renderWhatWeDoSection = () => {
    return (
      <div className={styles.whatWeDoMainContainerStyles}>
        <h3 className={styles.whatWeDoHeadingTextStyles}>What we do:</h3>
        <div className={styles.whatWeDoRightContainerStyles}>
          {whatWeDoListData.map((item, index) => (
            <div key={index} className={styles.whatWeDoContainerStyles}>
              <Divider customDividerStyles={styles.customDividerStyles} />
              <div className={styles.whatWeDoContentStyles}>
                <p className={styles.whatWeDoNameTextStyles}>
                  {item.id}.{item.name}
                </p>
                <p className={styles.whatWeDoDescTextStyles}>{item.desc}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };
  const handleRightClick = () => {
    const cardsEles = [...document.querySelectorAll('#cards')];
    cardsEles.forEach((item, index) => {
      if (index === 0) {
        item.classList.remove(item.className);
        // item.classList.add(CardClassName[CardClassName.length - 1]);
        item.classList.add(styles.approachCardLeftHideStyles);
      } else {
        item.classList.remove(item.className);
        item.classList.add(CardClassName[index - 1]);
      }
    });
    if (stepCount === approachStepsData.length) {
      setStepCount(1);
    } else {
      setStepCount(stepCount + 1);
    }
  };
  const handleLeftClick = () => {
    const cardsEles = [...document.querySelectorAll('#cards')];
    cardsEles.forEach((item, index) => {
      item.classList.remove(item.className);
      if (index === cardsEles.length - 1) {
        // item.classList.add(CardClassName[0]);
        item.classList.add(styles.approachCardHideStyles);
      } else {
        item.classList.add(cardsEles[index + 1].className);
      }
    });
    if (stepCount === 1) {
      setStepCount(approachStepsData.length);
    } else {
      setStepCount(stepCount - 1);
    }
  };
  const approachStepCard = (step) => {
    return (
      <div
        className={styles.approachCardStyles}
        style={{
          background: step.bgColor,
          border: step.border && `1px solid ${step.border}`,
        }}
      >
        <div></div>
        {step.image && (
          <div className={styles.stepImageStyles}>
            <img
              src={step.image}
              alt="step icon"
              className={styles.imageWidthStyles}
            />
          </div>
        )}
        <p
          className={styles.stepNameStyles}
          style={{ color: step.color, borderTop: `1px solid ${step.color}33` }}
        >
          {step.name}
        </p>
      </div>
    );
  };
  const renderApproachStepsSection = () => {
    return (
      <div className={styles.approachMainContainerStyles}>
        <h2 className={styles.approachHeadingTextStyles}>
          {strings.aboutStrings.headerThree}
        </h2>
        <div className={styles.approachStepsContainerStyles}>
          <div className={styles.approachStepsCardsContainerStyles}>
            {approachStepsData.map((item, index) => {
              return (
                <div
                  className={
                    (index === 0 && styles.approachCardOneStyles) ||
                    (index === 1 && styles.approachCardTwoStyles) ||
                    // (index > 1 && styles.approachCardHideStyles)

                    // ||
                    // (index === 2 && styles.approachCardThreeStyles) ||
                    // (index > 2 && styles.approachCardHideStyles)
                    // (index === 3 && styles.approachCardFourStyles) ||
                    // (index === 7 && styles.approachCardLeftHideStyles) ||
                    (index > 1 && styles.approachCardHideStyles)
                  }
                  key={index}
                  id="cards"
                >
                  {approachStepCard(item)}
                  {/* <div className={styles.approachStepCardStyles}>
                    <p className={styles.stepNumberTextStyles}>0{item.id}</p>
                    <p className={styles.stepNameTextStyles}>{item.name}</p>
                  </div> */}
                </div>
              );
            })}
            {/* <div className={styles.approachCardOneStyles}>
              <div className={styles.approachStepCardStyles}>
                <p className={styles.stepNumberTextStyles}>01</p>
                <p className={styles.stepNameTextStyles}>Onboarding</p>
              </div>
            </div>
            <div className={styles.approachCardTwoStyles}>
              <div className={styles.approachStepCardStyles}>
                <p className={styles.stepNumberTextStyles}>02</p>
                <p className={styles.stepNameTextStyles}>Onboarding</p>
              </div>
            </div>
            <div className={styles.approachCardThreeStyles}>
              <div className={styles.approachStepCardStyles}>
                <p className={styles.stepNumberTextStyles}>03</p>
                <p className={styles.stepNameTextStyles}>Onboarding</p>
              </div>
            </div>
            <div className={styles.approachCardFourStyles} >
              <div className={styles.approachStepCardStyles}>
                <p className={styles.stepNumberTextStyles}>04</p>
                <p className={styles.stepNameTextStyles}>Onboarding</p>
              </div>
            </div> */}
          </div>
          <div className={styles.approachStepsBottomContainerStyles}>
            <div className={styles.approachStepsButtonGroupStyles}>
              <Button
                btnName={
                  <LeftArrow
                    PathStyles={
                      stepCount === 1
                        ? styles.leftArrowDisablePathStyles
                        : styles.leftArrowPathStyles
                    }
                  />
                }
                customBtnStyles={
                  stepCount === 1
                    ? styles.disabledButtonStyles
                    : styles.leftArrowBtnStyles
                }
                onClick={() => stepCount !== 1 && handleLeftClick()}
              />
              <Button
                btnName={
                  <RightArrow
                    PathStyles={
                      stepCount === approachStepsData.length - 1
                        ? styles.rightArrowDisablePathStyles
                        : styles.rightArrowPathStyles
                    }
                  />
                }
                customBtnStyles={
                  stepCount === approachStepsData.length - 1
                    ? styles.disabledButtonStyles
                    : styles.rightArrowBtnStyles
                }
                onClick={() =>
                  stepCount !== approachStepsData.length - 1 &&
                  handleRightClick()
                }
              />
            </div>
            <p className={styles.stepCounterTextStyles}>
              0{stepCount}/0{approachStepsData.length - 1}
            </p>
          </div>
        </div>
      </div>
    );
  };
  const renderTeamValuesSection = () => {
    // return (
    //   <div className={styles.valuesMainContainerStyles}>
    //     <h2 className={styles.valuesHeadingTextStyles}>
    //       A strong team comes with strong values:
    //     </h2>
    //     <div className={styles.valuesContentContainer}>
    //       {teamValuesData.map((item, index) => (
    //         <div key={index} className={styles.valuesBlockStyles}>
    //           <h4 className={styles.valuesBlockHeadingTextStyles}>
    //             {item.head}
    //           </h4>
    //           <p className={styles.valuesBlockDescTextStyles}>{item.desc}</p>
    //         </div>
    //       ))}
    //     </div>
    //   </div>
    // );
    return (
      <div className={styles.teamValuesMainContainerStyles}>
        <div className={styles.valuesCardsContainerStyles}>
          <div className={styles.ValuesCardsTopContainerStyles}>
            <p className={styles.teamValuesHeadTextStyles}>
              A brand can only be as robust as their core values:
            </p>
            <ServiceCard
              service={teamValuesData[0]}
              customStyles={styles.teamValuesCardStyles}
              iconStyles={styles.teamValueIconStyles}
              teamValues={true}
            />
          </div>
          <div className={styles.valuesCardsMiddleContainerStyles}>
            <ServiceCard
              service={teamValuesData[1]}
              customStyles={styles.teamValuesCardStyles}
              iconStyles={styles.teamValueIconStyles}
              teamValues={true}
            />
            <ServiceCard
              service={teamValuesData[2]}
              customStyles={styles.teamValuesCardStyles}
              iconStyles={styles.teamValueIconStyles}
              teamValues={true}
            />
          </div>
          <div className={styles.valuesCardsBottomContainerStyles}>
            <ServiceCard
              service={teamValuesData[3]}
              customStyles={styles.teamValuesCardStyles}
              iconStyles={styles.teamValueIconStyles}
              teamValues={true}
            />
          </div>
        </div>
        <div className={styles.valuesCardsMobileViewContainerStyles}>
          <p className={styles.teamValuesHeadTextStyles}>
            A brand can only be as robust as their core values:
          </p>
          <div className={styles.teamsValueCardsMobileContainerStyles}>
            {teamValuesData.map((item, index) => (
              <ServiceCard
                key={index}
                service={item}
                customStyles={styles.teamValuesCardStyles}
                iconStyles={styles.teamValueIconStyles}
                customNameStyles={styles.teamNAmeTextStyles}
                teamValues={true}
              />
            ))}
          </div>
        </div>
      </div>
    );
  };
  const teamCard = (team) => {
    return (
      <div className={styles.temCardContainerStyles}>
        <h5 className={styles.teamTypeTextStyles}>{team.name}</h5>
        {team.desc && (
          <p className={styles.teamMemberNameTextStyles}>{team.desc}</p>
        )}
        {team?.members && (
          <div className={styles.teamMembersListContainerStyles}>
            {team?.members?.map((member, index) => (
              <div key={index} className={styles.teamMemberNameTextStyles}>
                <div className={styles.dotSymbolStyles}></div>
                {member.name} &nbsp;
                <span className={styles.roleTextStyles}>({member.role})</span>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };
  const renderOurTeamSection = () => {
    return (
      <div className={styles.ourTeamMainContainerStyles}>
        <h4 className={styles.ourTemHeadTextStyles}>
          {/* We’re a team of problem solvers, designers, developers, and more
          importantly passionate individuals. */}
          {strings.aboutStrings.WhatWeDo}
        </h4>
        <div className={styles.teamValuesCardsContainerStyles}>
          <div className={styles.teamCardsTopContainerStyles}>
            {teamCard(whatWeDoListData[0])}
            {teamCard(whatWeDoListData[1])}
          </div>
          <div className={styles.teamCardsBottomContainerStyles}>
            {teamCard(whatWeDoListData[2])}
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className={styles.aboutMainContainerStyles}>
      <Helmet>
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js-eu1.hs-scripts.com/27027103.js"
        ></script>
      </Helmet>
      <NavBar />
      {renderAboutBannerSection()}
      {renderTeamValuesSection()}
      {renderApproachStepsSection()}
      {renderOurTeamSection()}
      {/* {renderWhatWeDoSection()} */}
      <Footer
        theme="dark"
        customFooterBottomStyles={styles.footerStyles}
        customFooterMainContainerStyles={styles.footerMainContainerStyles}
      />
    </div>
  );
};

export default About;
