import React, { useContext, useEffect, useRef, useState } from 'react';
import styles from './styles.module.css';
import {
  Logo,
  MinusIcon,
  MobileLogo,
  PlusIcon,
  RightDownArrow,
  RightThickTriangleIcon,
} from '../../resources/svg';
import { useLocation, useNavigate } from 'react-router';
import { AppDataContext } from '../../providers/appDataProvider';
import CookiePolicyPopUp from '../cookiepolicypopup';

const NavBar = (props) => {
  const { theme } = props;
  const {
    currentLanguage,
    setLanguage,
    strings,
    showCookiePopUp,
    setShowCookiePopUp,
  } = useContext(AppDataContext);
  const { navbarStrings } = strings;
  const navigate = useNavigate();
  const location = useLocation();
  const langRef = useRef(null);

  const [showMenu, setShowMenu] = useState(false);
  const [showLanguagePopup, setShowLanguagePopup] = useState(false);
  const [activeLink, setActiveLink] = useState('/');

  useEffect(() => {
    !showMenu && setShowLanguagePopup(false);
    document.body.style.cssText = showMenu
      ? 'overflow:hidden'
      : 'overflow:auto';
    setActiveLink(location.pathname);
  }, [showMenu, location.pathname]);

  useEffect(() => {
    const langButton = [...document.querySelectorAll('#langButton')];
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      const isClickedOutside = langButton.every(
        (item) => item.contains(e.target) === false
      );
      // then close the menu
      if (showLanguagePopup && isClickedOutside) {
        setShowLanguagePopup(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [showLanguagePopup]);

  // useEffect(() => {
  //   const lanBtn = document.getElementById('abc');
  //   const engBtn = document.getElementById('ENGLISH');
  //   const sweBtn = document.getElementById('SWEDISH');

  //   const handleClickOutside = (event) => {
  //     console.log(event.target, lanBtn);
  //     console.log(lanBtn.contains(event.target), 'condition');
  //     if (lanBtn.contains(event.target)) {
  //       setShowLanguagePopup(false);
  //     }
  //   };
  //   const handleEscapeKey = (event) => {
  //     if (event.key === 'Escape') {
  //       setShowLanguagePopup(false);
  //     }
  //   };
  //   if (showLanguagePopup) {
  //     document.addEventListener('mousedown', handleClickOutside);
  //     document.addEventListener('keydown', handleEscapeKey);
  //   }
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //     document.removeEventListener('keydown', handleEscapeKey);
  //   };
  // }, [showLanguagePopup]);

  const navLinksData = [
    { title: strings.navbarStrings.about, route: '/about' },
    { title: strings.navbarStrings.projects, route: '/projects' },
    { title: strings.navbarStrings.services, route: '/services' },
    { title: strings.navbarStrings.contact, route: '/contact' },
    { title: strings.navbarStrings.insights, route: '/insights' },
  ];

  const handleLinkClick = (path) => {
    navigate(path);
    setActiveLink(path);
    setShowMenu(false);
  };
  const changeLanguage = (language) => {
    setShowLanguagePopup(false);
    setLanguage(language);
  };

  // const renderLanguageButton = () => {
  //   return showLanguagePopup ? (
  //     <div
  //       className={styles.languageSelectionContainerStyles}
  //       ref={langRef}
  //       id="langButton"
  //     >
  //       {['English', 'Swedish'].map((item) => (
  //         <p
  //           key={item}
  //           className={
  //             currentLanguage === item
  //               ? theme
  //                 ? styles.activeLanguageDarkTextStyles
  //                 : styles.activeLanguageTextStyles
  //               : theme
  //               ? styles.inActiveLanguageDarkTextStyles
  //               : styles.inActiveLanguageTextStyles
  //           }
  //           onClick={() => changeLanguage(item)}
  //           id={item}
  //         >
  //           {currentLanguage === item && (
  //             <RightThickTriangleIcon color={theme ? '#000AFF' : '#00ff9b'} />
  //           )}
  //           {item}
  //         </p>
  //       ))}
  //     </div>
  //   ) : (
  //     <div
  //       className={
  //         // showLanguagePopup
  //         //   ? styles.languageSelectionContainerStyles
  //         styles.languageButtonContainerStyles
  //       }
  //       onClick={() => {
  //         !showLanguagePopup && setShowLanguagePopup(true);
  //       }}
  //     >
  //       <p className={styles.languageTypeTextStyles}>
  //         {currentLanguage === 'English' ? 'ENGLISH' : 'SWEDISH'}
  //         {/* {currentLanguage} */}
  //       </p>
  //       <RightDownArrow
  //         color={theme && '#fff'}
  //         customStyles={styles.RightDownArrowStyles}
  //       />
  //     </div>
  //   );
  // };

  const renderLanguageButton = () => {
    return (
      <div
        className={
          showLanguagePopup
            ? styles.languageSelectionContainerStyles
            : styles.languageButtonContainerStyles
        }
        onClick={() => {
          !showLanguagePopup && setShowLanguagePopup(true);
        }}
        ref={langRef}
        id="langButton"
      >
        {showLanguagePopup ? (
          <
            // id="langButton"
            // className={styles.languageButtonSelectionContainerStyles}
          >
            {['English', 'Swedish'].map((item) => (
              <p
                key={item}
                className={
                  currentLanguage === item
                    ? theme
                      ? styles.activeLanguageDarkTextStyles
                      : styles.activeLanguageTextStyles
                    : theme
                    ? styles.inActiveLanguageDarkTextStyles
                    : styles.inActiveLanguageTextStyles
                }
                onClick={() => changeLanguage(item)}
                id={item}
              >
                {currentLanguage === item && (
                  <RightThickTriangleIcon
                    color={theme ? '#000AFF' : '#00ff9b'}
                  />
                )}
                {item}
              </p>
            ))}
          </>
        ) : (
          <>
            <p className={styles.languageTypeTextStyles}>
              {currentLanguage === 'English' ? 'ENGLISH' : 'SWEDISH'}
              {/* {currentLanguage} */}
            </p>
            <RightDownArrow
              color={theme && '#fff'}
              customStyles={styles.RightDownArrowStyles}
            />
          </>
        )}
      </div>
    );
  };

  const renderNavbar = () => {
    return (
      <div
        className={
          theme === 'dark'
            ? showMenu
              ? styles.navBarDarkContainerStyles
              : styles.navbarLightStyles
            : showMenu
            ? styles.navBarContainerStyles
            : styles.navbarDarkStyles
        }
        style={{
          top:
            window.innerWidth < 568
              ? '24px'
              : showCookiePopUp && !showMenu && '64px',
        }}
      >
        <div
          className={styles.navbarLeftStyles}
          onClick={() => handleLinkClick('/')}
        >
          <Logo
            color={theme === 'dark' ? (showMenu ? '#fff' : '#000') : '#000'}
            customStyles={styles.logoStyles}
          />
          <MobileLogo
            color={theme === 'dark' ? '#fff' : '#000'}
            customStyles={styles.mobileLogoStyles}
          />
        </div>
        <div className={styles.languageButtonViewStyles}>
          {showMenu && renderLanguageButton()}
        </div>
        <div
          className={styles.navbarRightStyles}
          onClick={() => setShowMenu(!showMenu)}
        >
          {showMenu ? (
            <MinusIcon color={theme === 'dark' && showMenu ? '#fff' : '#000'} />
          ) : (
            <PlusIcon color={theme === 'dark' && showMenu ? '#fff' : '#000'} />
          )}
          <p className={styles.menuTextStyles}>
            {showMenu ? navbarStrings.close : navbarStrings.menu}
          </p>
        </div>
      </div>
    );
  };
  const renderNavbarFooterSection = () => {
    return (
      <div
        className={
          theme
            ? styles.footerDarkBottomContainerStyle
            : styles.footerBottomContainerStyle
        }
      >
        <div
          className={styles.footerLeftContainerStyles}
          onClick={() =>
            window.open(
              'https://www.google.com/maps/place/Hetch/@56.0421623,12.6887266,17z/data=!3m1!4b1!4m15!1m7!3m6!1s0x465233d3ba914591:0x638f0780eecc5b1d!2sHetch!8m2!3d56.0417467!4d12.6913868!16s%2Fg%2F11j0slh4g2!3m6!1s0x4652331ac5ac6bd7:0x19a6c41e9a698639!8m2!3d56.0421623!4d12.6913069!15sCjZIRVRDSCBBQiwgSEVOQ0tFTFMgVE9SRyAzLCAgMjUyIDI1IEhFTFNJTkdCT1JHLCBTV0VERU6SARBjb3Jwb3JhdGVfb2ZmaWNl4AEA!16s%2Fg%2F11t9_jbt_v?entry=ttu',
              '_blank'
            )
          }
        >
          <p
            className={
              theme ? styles.addressDarkTextStyles : styles.addressTextStyles
            }
          >
            HETCH AB, HENCKELS TORG 3,
          </p>
          <p
            className={
              theme ? styles.addressDarkTextStyles : styles.addressTextStyles
            }
          >
            252 25 HELSINGBORG, SWEDEN
          </p>
        </div>
        <div className={styles.footerMiddleContainerStyles}>
          <p
            className={
              theme ? styles.footerDarkLinkStyles : styles.footerLinkStyles
            }
            // href="mailto:bharath@viljetech.com"
          >
            bharath@viljetech.com
          </p>

          <p
            className={
              theme ? styles.footerDarkLinkStyles : styles.footerLinkStyles
            }
          >
            +46(0)724430170
          </p>
        </div>
        <div className={styles.footerRightContainerStyles}>
          <p
            className={
              theme ? styles.footerDarkLinkStyles : styles.footerLinkStyles
            }
            onClick={() =>
              window.open(
                'https://instagram.com/viljetech?igshid=MzRlODBiNWFlZA==',
                '_blank'
              )
            }
          >
            INSTAGRAM
          </p>
          <p
            className={
              theme ? styles.footerDarkLinkStyles : styles.footerLinkStyles
            }
            onClick={() =>
              window.open(
                'https://www.linkedin.com/company/vilje-tech/',
                '_blank'
              )
            }
          >
            LINKEDIN
          </p>
        </div>
      </div>
    );
  };
  const renderNavbarMenuSection = () => {
    return (
      <div
        className={
          showMenu
            ? (theme === 'dark' && styles.navbarDarkMenuContainerStyles) ||
              styles.navbarMenuOpenContainerStyles
            : styles.navbarMenuCloseContainerStyles
        }
      >
        <div className={styles.navbarInnerContainerStyles}>
          <div className={styles.navbarTopMenuLinksContainerStyles}>
            <div className={styles.languageButtonMobileViewStyles}>
              {showMenu && renderLanguageButton()}
            </div>
            <div className={styles.navLinksContainerStyles}>
              {navLinksData?.map((navLink) => (
                <p
                  className={
                    activeLink === navLink.route
                      ? theme === 'dark'
                        ? styles.navLinkActiveDarkTextStyles
                        : styles.navLinkActiveTextStyles
                      : theme === 'dark'
                      ? styles.navLinkDarkTextStyles
                      : styles.navLinkTextStyles
                  }
                  onClick={() => handleLinkClick(navLink.route)}
                  key={navLink.title}
                >
                  {navLink.title}
                  {activeLink === navLink.route && '*'}
                </p>
              ))}
            </div>
          </div>
          {renderNavbarFooterSection()}
        </div>
      </div>
    );
  };
  return (
    <div className={[styles.navbarMainContainerStyles].join(' ')}>
      {showCookiePopUp && (
        <CookiePolicyPopUp
          setShowCookiePopUp={setShowCookiePopUp}
          theme={theme}
          showMenu={showMenu}
        />
      )}
      {renderNavbar()}
      {renderNavbarMenuSection()}
    </div>
  );
};

export default NavBar;
