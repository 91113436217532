import React, { useContext, useEffect } from 'react';
import styles from './styles.module.css';
import NavBar from '../../components/navbar';
import Divider from '../../components/divider';
import Footer from '../../components/footer';
import { Helmet } from 'react-helmet';
import { AppDataContext } from '../../providers/appDataProvider';
const PrivacyPolicy = () => {
  const { strings } = useContext(AppDataContext);
  const privacyPolicyData = [
    {
      heading: strings.privacyPoliyStrings.dataController,
      para1: strings.privacyPoliyStrings.dataControllerDescOne,
      para2: strings.privacyPoliyStrings.dataControllerDescTwo,
    },
    {
      heading: strings.privacyPoliyStrings.about,
      para1: strings.privacyPoliyStrings.aboutDescOne,
    },
    {
      heading: strings.privacyPoliyStrings.personal,
      para1: strings.privacyPoliyStrings.personalDescOne,
      para2: strings.privacyPoliyStrings.personalDescTwo,
      para3: strings.privacyPoliyStrings.personalDescThree,
      para4: strings.privacyPoliyStrings.personalDescFour,
    },
    {
      heading: strings.privacyPoliyStrings.purpose,
      para1: strings.privacyPoliyStrings.purposeDescOne,
      para2: strings.privacyPoliyStrings.purposeDescTwo,
      para3: strings.privacyPoliyStrings.purposeDescThree,
    },
    {
      heading: strings.privacyPoliyStrings.dataRetention,
      para1: strings.privacyPoliyStrings.dataRetentionDescOne,
      para2: strings.privacyPoliyStrings.dataRetentionDescTwo,
      para2Link: strings.privacyPoliyStrings.dataRetentionDescTwoLink,
    },
    {
      heading: strings.privacyPoliyStrings.employmentApplications,
      para1: strings.privacyPoliyStrings.employmentApplicationsDescOne,
    },
    {
      heading: strings.privacyPoliyStrings.thirdParties,
      para1: strings.privacyPoliyStrings.thirdPartiesDescOne,
      para2: strings.privacyPoliyStrings.thirdPartiesDescTwo,
      para3: strings.privacyPoliyStrings.thirdPartiesDescThree,
      para4: strings.privacyPoliyStrings.thirdPartiesDescFour,
      para5: strings.privacyPoliyStrings.thirdPartiesDescFive,
      para5Link: strings.privacyPoliyStrings.thirdPartiesDescLink,
    },

    {
      heading: strings.privacyPoliyStrings.cookies,
      para1: strings.privacyPoliyStrings.cookiesDescOne,
      para2: strings.privacyPoliyStrings.cookiesDescTwo,
      para3: strings.privacyPoliyStrings.cookiesDescThree,
      para3Link: strings.privacyPoliyStrings.cookiesDescThreeLink,
      para4: strings.privacyPoliyStrings.cookiesDescFour,
      para5: strings.privacyPoliyStrings.cookiesDescFive,
    },
    {
      heading: strings.privacyPoliyStrings.cybersecurity,
      para1: strings.privacyPoliyStrings.cybersecurityDescOne,
    },
    {
      heading: strings.privacyPoliyStrings.yourRights,
      para1: strings.privacyPoliyStrings.yourRightsDescOne,
      para2: strings.privacyPoliyStrings.yourRightsDescTwo,
      para3: strings.privacyPoliyStrings.yourRightsDescThree,
      para3Link: strings.privacyPoliyStrings.yourRightsDescThreeLink,
      para4: strings.privacyPoliyStrings.yourRightsDescFour,
      para5: strings.privacyPoliyStrings.yourRightsDescFive,
      para5Link: strings.privacyPoliyStrings.yourRightsDescFiveLinkOne,
      para5LinkTwo: strings.privacyPoliyStrings.yourRightsDescFiveLinkTwo,
    },
    {
      heading: strings.privacyPoliyStrings.contactInformation,
      para1: strings.privacyPoliyStrings.contactInformationDescOne,
      para1Link: strings.privacyPoliyStrings.contactInformationDescOneLink,
    },
  ];

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className={styles.privacyPolicyMainContainerStyles}>
      <Helmet>
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js-eu1.hs-scripts.com/27027103.js"
        ></script>
      </Helmet>
      <NavBar theme={'dark'} />
      <div className={styles.privacyPolicyInnerContainerStyles}>
        <div className={styles.headingContainerStyles}>
          <h1 className={styles.headingTextStyles}>
            {strings.privacyPoliyStrings.privacyPolicy}
          </h1>
          <p className={styles.headDescTextStyles}>
            {strings.privacyPoliyStrings.headDesc}
          </p>
        </div>
        <div className={styles.ContentContainerStyles}>
          {privacyPolicyData.map((item, index) => (
            <div key={index} className={styles.contentBlockStyles}>
              <h4 className={styles.subHeadingTextStyles}>{item.heading}</h4>
              {item?.para1 && (
                <p className={styles.descTextStyles}>
                  {item.para1}
                  {item.para1Link && (
                    <a
                      className={styles.descLinkTextStyles}
                      href={`mailto:bharath@viljetech.com`}
                    >
                      {item.para1Link}
                    </a>
                  )}
                </p>
              )}
              {item?.para2 && typeof item?.para2 === 'object' ? (
                <div>
                  {item?.para2.map((item, index) => (
                    <p key={index} className={styles.descTextStyles}>
                      {item}
                    </p>
                  ))}
                </div>
              ) : (
                item?.para2 && (
                  <p className={styles.descTextStyles}>
                    {item.para2}
                    {item.para2Link && (
                      <a
                        className={styles.descLinkTextStyles}
                        href={`mailto:bharath@viljetech.com`}
                      >
                        {item.para2Link}
                      </a>
                    )}
                  </p>
                )
              )}
              {item?.para3 && typeof item?.para3 === 'object' ? (
                <div>
                  {item?.para3.map((item, index) => (
                    <p key={index} className={styles.descTextStyles}>
                      {item}
                    </p>
                  ))}
                </div>
              ) : (
                item?.para3 && (
                  <p className={styles.descTextStyles}>
                    {item.para3}
                    {item.para3Link && (
                      <a
                        className={styles.descLinkTextStyles}
                        href={
                          item.para3Link === ` bharath@viljetech.com`
                            ? `mailto:bharath@viljetech.com`
                            : `https://pts.se/`
                        }
                        rel="noreferrer"
                        target="_blank"
                      >
                        {item.para3Link}
                      </a>
                    )}
                  </p>
                )
              )}
              {item.para4 && (
                <p className={styles.descTextStyles}>{item.para4}</p>
              )}
              {item.para5 && typeof item?.para5 === 'object' ? (
                <div>
                  {item?.para5.map((item, index) => (
                    <p key={index} className={styles.descTextStyles}>
                      {item}
                    </p>
                  ))}
                </div>
              ) : (
                item.para5 && (
                  <p className={styles.descTextStyles}>
                    {item.para5}
                    {item.para5Link && (
                      <a
                        className={styles.descLinkTextStyles}
                        href={
                          item.para5Link === ` bharath@viljetech.com`
                            ? `mailto:bharath@viljetech.com`
                            : `https://www.imy.se/`
                        }
                        rel="noreferrer"
                        target="_blank"
                      >
                        {item.para5Link}
                      </a>
                    )}
                    {item.para5LinkTwo && 'and'}
                    {item.para5LinkTwo && (
                      <a
                        className={styles.descLinkTextStyles}
                        href={'https://commission.europa.eu/'}
                        rel="noreferrer"
                        target="_blank"
                      >
                        {item.para5LinkTwo}
                      </a>
                    )}
                  </p>
                )
              )}
            </div>
          ))}
        </div>
      </div>
      <Divider />
      <Footer
        footerBottom={true}
        customFooterBottomStyles={styles.footerStyles}
        customFooterMainContainerStyles={styles.footerMainContainerStyles}
      />
    </div>
  );
};

export default PrivacyPolicy;
