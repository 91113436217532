import React, { useContext, useState } from 'react';
import styles from './styles.module.css';
import { RightUpArrow } from '../../resources/svg';
import { swedenFlag } from '../../resources/images';
import { useNavigate } from 'react-router';
import { AppDataContext } from '../../providers/appDataProvider';

const Footer = (props) => {
  const { strings } = useContext(AppDataContext);
  const {
    theme,
    footerBottom,
    customFooterBottomStyles,
    customFooterMainContainerStyles,
  } = props;
  const [email, setEmail] = useState({ email: '' });
  const navigate = useNavigate();
  const renderFooterTopSection = () => {
    return (
      <div className={styles.footerTopContainerStyles}>
        <div className={styles.footerHeadingContainerStyles}>
          <h2
            className={
              theme === 'dark'
                ? styles.footerHeadingDarkTextStyles
                : styles.footerHeadingTextStyles
            }
            onClick={() => navigate('/contact')}
          >
            {strings.footerStrings.header}
            <RightUpArrow
              customStyles={styles.rightUpArrowStyles}
              pathStyles={
                theme
                  ? styles.rightUpArrowDarkPathStyles
                  : styles.rightUpArrowPathStyles
              }
            />
          </h2>
        </div>
        <div className={styles.footerTopRightContainerStyles}>
          <p
            className={
              theme
                ? styles.footerTopDescDarkTextStyles
                : styles.footerTopDescTextStyles
            }
          >
            {strings.footerStrings.descp}
          </p>
          <input
            type="email"
            name="email"
            placeholder={strings.footerStrings.email}
            className={
              theme ? styles.emailInputDarkStyles : styles.emailInputStyles
            }
            value={email.email}
            onChange={(e) => setEmail({ email: e.target.value })}
          />
          <p
            className={
              theme
                ? styles.subscribeDarkTextStyles
                : styles.subscribeTextStyles
            }
            onClick={() => {
              console.log(email);
              setEmail({ email: '' });
            }}
          >
            {strings.footerStrings.subscribe}
          </p>
        </div>
      </div>
    );
  };
  const renderFooterBottomSection = () => {
    return (
      <div
        className={[
          styles.footerBottomContainerStyle,
          customFooterBottomStyles,
        ].join(' ')}
      >
        <div className={styles.footerLinksContainerStyles}>
          <div
            className={styles.addressLinkContainerStyles}
            onClick={() =>
              window.open(
                'https://www.google.com/maps/place/Hetch/@56.0421623,12.6887266,17z/data=!3m1!4b1!4m15!1m7!3m6!1s0x465233d3ba914591:0x638f0780eecc5b1d!2sHetch!8m2!3d56.0417467!4d12.6913868!16s%2Fg%2F11j0slh4g2!3m6!1s0x4652331ac5ac6bd7:0x19a6c41e9a698639!8m2!3d56.0421623!4d12.6913069!15sCjZIRVRDSCBBQiwgSEVOQ0tFTFMgVE9SRyAzLCAgMjUyIDI1IEhFTFNJTkdCT1JHLCBTV0VERU6SARBjb3Jwb3JhdGVfb2ZmaWNl4AEA!16s%2Fg%2F11t9_jbt_v?entry=ttu',
                '_blank'
              )
            }
          >
            <p
              className={
                theme === 'dark'
                  ? styles.addressLinkDarkTextStyles
                  : styles.addressLinkTextStyles
              }
            >
              {strings.footerStrings.location}
            </p>
            <p
              className={
                theme === 'dark'
                  ? styles.addressLinkDarkTextStyles
                  : styles.addressLinkTextStyles
              }
            >
              {strings.footerStrings.country}
            </p>
          </div>
          <div className={styles.footerLinksSubContainerStyles}>
            <p
              className={
                theme === 'dark'
                  ? styles.footerLinkDarkTextStyles
                  : styles.footerLinkTextStyles
              }
            >
              {strings.footerStrings.mail}
            </p>

            <p
              className={
                theme === 'dark'
                  ? styles.footerLinkDarkTextStyles
                  : styles.footerLinkTextStyles
              }
            >
              {strings.footerStrings.mobileNum}
            </p>
          </div>
          <div className={styles.footerLinksSubContainerStyles}>
            <p
              className={
                theme === 'dark'
                  ? styles.footerLinkDarkTextStyles
                  : styles.footerLinkTextStyles
              }
              onClick={() =>
                window.open(
                  'https://instagram.com/viljetech?igshid=MzRlODBiNWFlZA==',
                  '_blank'
                )
              }
            >
              {strings.footerStrings.insta}
            </p>
            <p
              className={
                theme === 'dark'
                  ? styles.footerLinkDarkTextStyles
                  : styles.footerLinkTextStyles
              }
              onClick={() =>
                window.open(
                  'https://www.linkedin.com/company/vilje-tech/',
                  '_blank'
                )
              }
            >
              {strings.footerStrings.linkedin}
            </p>
          </div>
          <div className={styles.footerLinksSubContainerStyles}>
            <p
              className={
                theme === 'dark'
                  ? styles.footerLinkDarkTextStyles
                  : styles.footerLinkTextStyles
              }
            >
              {strings.footerStrings.terms}
            </p>
            <p
              className={
                theme === 'dark'
                  ? styles.footerLinkDarkTextStyles
                  : styles.footerLinkTextStyles
              }
              onClick={() => window.open('/privacypolicy', '_blank')}
            >
              {strings.footerStrings.cookies}
            </p>
          </div>
        </div>
        <div className={styles.footerBottomBottomStyles}>
          <div>
            <p
              className={
                theme === 'dark'
                  ? styles.allRightsDarkTextStyles
                  : styles.allRightsTextStyles
              }
            >
              {strings.footerStrings.rights}
            </p>
            <p
              className={
                theme === 'dark'
                  ? styles.allRightsDarkTextStyles
                  : styles.allRightsTextStyles
              }
            >
              {strings.footerStrings.regdNo}
            </p>
          </div>
          <div className={styles.flagImageContainerStyles}>
            <img
              src={swedenFlag}
              alt="sweden flag"
              className={styles.imageWidthStyles}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={[
        styles.footerMainContainerStyles,
        customFooterMainContainerStyles,
      ].join(' ')}
    >
      {!footerBottom && renderFooterTopSection()}
      {renderFooterBottomSection()}
    </div>
  );
};

export default Footer;
