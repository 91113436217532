import React, { useContext, useEffect, useState } from 'react';
import NavBar from '../../components/navbar';
import Divider from '../../components/divider';
import Footer from '../../components/footer';
import BlogCard from '../../components/blogcard';
import styles from './styles.module.css';
import { Helmet } from 'react-helmet';
import { insightsData } from '../../commondata';
import { AppDataContext } from '../../providers/appDataProvider';
const Blogs = () => {
  const { strings } = useContext(AppDataContext);
  const tabsData = [
    strings.blogStrings.all,
    strings.blogStrings.blogs,
    strings.blogStrings.news,
  ];

  const [activeTab, setActiveTab] = useState('All');
  const [cardsData, setCardsData] = useState(insightsData);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  const handleTabClick = (item) => {
    if (item === 'All') {
      setActiveTab(item);
      setCardsData(insightsData);
    } else {
      setActiveTab(item);
      const filteredCardsData = insightsData.filter(
        (blog) => blog.type === item
      );
      setCardsData(filteredCardsData);
    }
  };

  const renderBlogsContainerSection = () => {
    return (
      <div className={styles.blogsContainerStyles}>
        <div className={styles.blogsTopContainerStyles}>
          <h2 className={styles.blogsPageHeadingTextStyles}>
            {strings.blogStrings.headerOne}
          </h2>
          <div className={styles.tabNamesContainerStyles}>
            {tabsData.map((item, index) => (
              <p
                className={
                  item === activeTab
                    ? styles.activeTabNameTextStyles
                    : styles.tabNameTextStyles
                }
                key={index}
                onClick={() => handleTabClick(item)}
              >
                {item}
              </p>
            ))}
          </div>
        </div>
        {cardsData && cardsData.length > 0 && (
          <div className={styles.singleBlogCardsContainerStyles}>
            <BlogCard
              blog={cardsData[0]}
              size="large"
              customBlogCardStyles={styles.largeBlogCardStyles}
            />
          </div>
        )}
        {cardsData && cardsData.length > 0 && (
          <div className={styles.blogsCardsContainerStyles}>
            {cardsData?.map((item, index) => {
              if (index > 0) {
                return (
                  <BlogCard
                    blog={item}
                    key={index}
                    // onClick={() => navigate('/blogs/name')}
                  />
                );
              } else {
                return null;
              }
            })}
          </div>
        )}
        {cardsData.length === 0 && (
          <p className={styles.dataNotFoundTextStyles}>
            {strings.blogStrings.noData}
          </p>
        )}
        {/* <div className={styles.blogsCardsContainerStyles}>
          {cardsData.map((item, index) => {
            if (index > 3 && index <= 6) {
              return <BlogCard blog={item} key={index} />;
            } else {
              return null;
            }
          })}
        </div> */}
      </div>
    );
  };
  return (
    <div className={styles.blogsMainContainerStyles}>
      <Helmet>
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js-eu1.hs-scripts.com/27027103.js"
        ></script>
      </Helmet>
      <NavBar theme={'dark'} />
      {renderBlogsContainerSection()}
      <Divider />
      <Footer />
    </div>
  );
};

export default Blogs;
