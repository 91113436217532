import React, { useContext, useEffect, useState } from 'react';
import styles from './styles.module.css';
import Divider from '../divider';
import Button from '../button';
import { LeftArrow, RightArrow } from '../../resources/svg';
import { AppDataContext } from '../../providers/appDataProvider';
// import { bannerImg1 } from '../../resources/images';

const TestimonialsSection = (props) => {
  const { strings } = useContext(AppDataContext);
  const { customTestimonialContainerStyles } = props;
  const [showTestimonial, setShowTestimonial] = useState(0);
  useEffect(() => {}, []);
  const clientNamesData = [
    strings.testimonialStrings.headerOne,
    strings.testimonialStrings.headerTwo,
    strings.testimonialStrings.headerThree,
    strings.testimonialStrings.headerFour,
    strings.testimonialStrings.headerFive,
  ];
  const clientTestimonials = [
    {
      desc: strings.testimonialStrings.descpOne,
      clientName: strings.testimonialStrings.clientNameOne,
    },
    {
      desc: strings.testimonialStrings.descpTwo,
      clientName: strings.testimonialStrings.clientNameTwo,
    },
    {
      desc: strings.testimonialStrings.descpThree,
      clientName: strings.testimonialStrings.clientNameThree,
    },
  ];
  const handleRightClick = (index) => {
    if (index < clientTestimonials.length - 1) {
      setShowTestimonial(index + 1);
    }
  };
  const handleLeftClick = (index) => {
    if (index !== 0) {
      setShowTestimonial(index - 1);
    }
  };
  const testimonialCard = () => {
    return (
      <div className={styles.testimonialCardStyles}>
        {clientTestimonials.map((item, index) => {
          return (
            showTestimonial === index && (
              <React.Fragment key={index}>
                <p className={styles.testimonialContentTextStyles}>
                  {item.desc}
                </p>
                <p className={styles.testimonialClientNameTextStyles}>
                  {item.clientName}
                </p>
                <Divider />
                <div className={styles.testimonialBottomStyles}>
                  <div className={styles.featuresButtonGroupStyles}>
                    <Button
                      btnName={
                        <LeftArrow
                          PathStyles={
                            index === 0
                              ? styles.leftArrowDisablePathStyles
                              : styles.leftArrowPathStyles
                          }
                        />
                      }
                      customBtnStyles={
                        index === 0
                          ? styles.disabledButtonStyles
                          : styles.leftArrowBtnStyles
                      }
                      onClick={() => {
                        handleLeftClick(index);
                      }}
                    />
                    <Button
                      btnName={
                        <RightArrow
                          PathStyles={
                            index === clientTestimonials.length - 1
                              ? styles.rightArrowDisablePathStyles
                              : styles.rightArrowPathStyles
                          }
                        />
                      }
                      customBtnStyles={
                        index === clientTestimonials.length - 1
                          ? styles.disabledButtonStyles
                          : styles.rightArrowBtnStyles
                      }
                      onClick={() => {
                        handleRightClick(index);
                      }}
                    />
                  </div>
                  <p className={styles.testimonialClientNameTextStyles}>
                    {`0${index + 1}/0${clientTestimonials.length}`}
                  </p>
                </div>
              </React.Fragment>
            )
          );
        })}
      </div>
    );
  };

  return (
    <div
      className={[
        styles.testimonialMainContainerStyles,
        customTestimonialContainerStyles,
      ].join(' ')}
    >
      <div className={styles.testimonialTopContainerStyles}>
        <div className={styles.testimonialTopLeftContainerStyles}>
          <p className={styles.testimonialLightDescTextStyles}>
            {strings.testimonialStrings.testimonialHeader}
            <span> &nbsp;{strings.testimonialStrings.clients}&nbsp;</span>
            {strings.testimonialStrings.and}
            <span>&nbsp;{strings.testimonialStrings.partner}&nbsp;</span>
            {strings.testimonialStrings.around}
          </p>
          <div className={styles.clientNamesListContainerStyles}>
            {clientNamesData.map((item, index) => (
              <div
                className={styles.clientNamesContainerStyles}
                key={index}
                // onMouseMove={(e) => {
                //   document.querySelector(
                //     `#hoveredImage${index}`
                //   ).style.left = `${e.clientX}px`;
                // }}
              >
                <p className={styles.clientNameTextStyles} key={index}>
                  {item}
                </p>
                {/* <div
                  className={styles.hoveredProjectImageStyles}
                  id={`hoveredImage${index}`}
                >
                  <img
                    src={bannerImg1}
                    className={styles.imageWidthStyles}
                    alt="project"
                  />
                </div> */}
              </div>
            ))}
          </div>
        </div>
        {/* <div className={styles.testimonialTopRightContainerStyles}></div> */}
      </div>
      <div className={styles.testimonialBottomContainerStyles}>
        <p className={styles.testimonialLightDescTextStyles}>
          {strings.testimonialStrings.know}
          <span>
            &nbsp;{strings.testimonialStrings.testimonialHeaderTwo}&nbsp;
          </span>
          {strings.testimonialStrings.aboutSpan}
        </p>
        {testimonialCard()}
      </div>
    </div>
  );
};

export default TestimonialsSection;
